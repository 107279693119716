<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <div class="d-flex align-items-center">
            <h4>Title: {{ json_data.publication.title }}</h4>
            <PremiumIndicator />
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/publication?key=501785822
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import PremiumIndicator from "@/components/common/PremiumIndicator";
export default {
  name: "PublicationDetail",
  components: {
    BaseEntry,
    PageNotFound,
    PremiumIndicator,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "publication/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let jsonId = this.$route.query.key;
    if (jsonId) {
      try {
        this.loading = true;
        let response = await PostsService.getPublicationDetail({
          key: jsonId,
        });
        this.json_data = response.data;
        this.processEntries();
        this.loading = false;
        document.title = `Publication Detail`;
      } catch (error) {
        console.error("Error fetching publication details: ", error);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.error = true;
      this.loading = false;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Authors",
        text: this.json_data.publication.authors,
      });
      bands.push({
        key: "Type",
        text: this.json_data.publication.publicationType,
      });
      bands.push({
        key: "Journal",
        text: this.json_data.publication.pubName,
      });
      bands.push({
        key: "Year",
        text: this.json_data.publication.publicationYear
          ? this.json_data.publication.publicationYear
          : "No data available",
      });
      bands.push({
        key: "Volume",
        text: this.json_data.publication.volume
          ? this.json_data.publication.volume
          : "No data available",
      });
      bands.push({
        key: "Issue",
        text: this.json_data.publication.issue
          ? this.json_data.publication.issue
          : "No data available",
      });
      bands.push({
        key: "Start Page",
        text: this.json_data.publication.pageStart
          ? this.json_data.publication.pageStart
          : "No data available",
      });
      let doiLink = this.json_data.publication.doi
        ? "https://doi.org/" + this.json_data.publication.doi
        : "";
      bands.push({
        key: "DOI",
        text: this.json_data.publication.doi
          ? this.json_data.publication.doi
          : "No DOI available",
        type: "link",
        link: doiLink,
      });
      let band = {
        key: "PubMed",
        text:
          this.json_data.publication.pubmedId == 0
            ? "No PubMed ID available"
            : this.json_data.publication.pubmedId,
      };

      if (this.json_data.publication.pubmedId != 0) {
        band.type = "link";
        band.link = `https://pubmed.ncbi.nlm.nih.gov/${this.json_data.publication.pubmedId}/`;
      }
      bands.push(band);
      bands.push(this.getCommunity());
      bands.push(this.getKeywords());
      bands.push(this.getLoci());
      bands.push(this.getAnnotationCount());
      // bands.push(this.getComments());
      bands.push({
        key: "TAIR Accession",
        text: `Publication:${this.json_data.publication.refid}`,
      });

      return bands;
    },

    //Getters
    getAnnotationCount() {
      let entry = {
        key: "Annotations Based on this Publication",
        type: "link",
        buttons: [],
      };
      if (
        this.json_data.annotationCount &&
        this.json_data.annotationCount > 0
      ) {
        let searchParams = {
          // keyword_types: ["grow", "stru"],
          searchTerms: [
            {
              search_type: "reference_id",
              search_method: "exactly",
              search_input: this.json_data.publication.refid,
            },
          ],
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=annotations&key=${key}`;
        entry.text = this.json_data.annotationCount;
        entry.link = link;
      } else {
        entry.type = "link";
        entry.text = "There are no annotations yet for this paper";
        entry.buttons.push({
          name: "Submit GO/PO Annotations",
          url: "https://goat.phoenixbioinformatics.org/",
          helpTxt: "What is this?",
          link: "https://tair-data.s3.us-west-2.amazonaws.com/download-forms/TOASThelp.pdf",
        });
      }
      return entry;
    },
    getCommunity() {
      let entry = {
        key: "Associated Community Members",
        type: "table",
        items: [],
        fields: [],
      };
      let community = this.json_data.communities;
      if (community == null || community.length == 0) {
        entry.type = "";
        entry.text = "No associated members";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        // {
        //   key: "email",
        //   label: "Email",
        //   cellType: "name_link",
        // },
      ];
      entry.items = community.map((c) => {
        return {
          name: {
            name: c.firstName + " " + c.lastName,
            link: `/person?key=${c.communityId}`,
          },
          // email: { name: c.email },
        };
      });
      return entry;
    },
    getKeywords() {
      let entry = {
        key: "Associated Keywords",
        type: "links_commasep",
        items: [],
      };
      let keywords = this.json_data.keywords;
      if (keywords == null || keywords.length == 0) {
        entry.type = "";
        entry.text = "No Keywords available";
        return entry;
      }
      entry.items = keywords.map((k) => {
        return {
          name: k.keyword,
          link: `/keyword?key=${k.keywordId}`,
        };
      });
      return entry;
    },
    getLoci() {
      let entry = {
        key: "Associated Loci",
        type: "links_commasep",
        items: [],
      };
      let loci = this.json_data.loci;
      if (loci == null || loci.length == 0) {
        entry.type = "";
        entry.text = "No Associated Loci available";
        return entry;
      }

      entry.items = loci.map((l) => {
        let text = l.name;
        if (l.symbol) {
          text += ` (${l.symbol})`;
        }
        return {
          name: text,
          link: `/locus?key=${l.locusId}`,
        };
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
