const redirectMapping = {
  "/servlets/Community": [
    {
      action: "view",
      type: "organization",
      targetPath: (query) => `/organization?key=${query.communityID}`,
    },
    {
      action: "view",
      type: "person",
      targetPath: (query) => `/person?key=${query.communityID}`,
    },
  ],
  "/servlets/TairObject": [
    {
      type: "gene",
      targetPath: (query) => {
        // Redirect based on the id or name parameter
        if (query.id) {
          return `/gene?key=${query.id}`;
        } else if (query.name) {
          return `/gene?name=${query.name}`;
        } else {
          return "/error"; // Redirect to an error page if neither parameter is available
        }
      },
    },
    {
      type: "vector",
      targetPath: (query) => `/vector?key=${query.id}`,
    },
    {
      type: "germplasm",
      targetPath: (query) => `/germplasm?key=${query.id}`,
    },
    {
      type: "locus",
      targetPath: (query) => {
        // Redirect based on the id or name parameter
        if (query.id) {
          return `/locus?key=${query.id}`;
        } else if (query.name) {
          return `/locus?name=${query.name}`;
        } else {
          return "/error"; // Redirect to an error page if neither parameter is available
        }
      },
    },
    {
      type: "clone",
      targetPath: (query) => {
        // Redirect based on the id or name parameter
        if (query.id) {
          return `/clone?key=${query.id}`;
        } else if (query.name) {
          return `/clone?name=${query.name}`;
        } else {
          return "/error"; // Redirect to an error page if neither parameter is available
        }
      },
    },
    {
      type: "marker",
      targetPath: (query) => `/marker?key=${query.id}`,
    },
    { type: "person", targetPath: (query) => `/person?key=${query.id}` },
    {
      type: "polyallele",
      targetPath: (query) => {
        // Redirect based on the id or name parameter
        if (query.id) {
          return `/polyallele?key=${query.id}`;
        } else if (query.name) {
          return `/polyallele?name=${query.name}`;
        } else {
          return "/error"; // Redirect to an error page if neither parameter is available
        }
      },
    },
    {
      type: "publication",
      targetPath: (query) => `/publication?key=${query.id}`,
    },
    {
      type: "species_variant",
      targetPath: (query) => `/ecotype?key=${query.id}`,
    },
    {
      type: "transposon",
      targetPath: (query) => `/transposon?key=${query.id}`,
    },
    {
      isDefault: true,
      targetPath: (query) => {
        if (query.accession) {
          const accession = query.accession;
          if (accession.includes(":")) {
            const [prefix, id] = accession.split(":");
            if (prefix === "clone_end") {
              return `/cloneend?accession=${id}`;
            }
            if (prefix === "polymorphism") {
              return `/polyallele?accession=${id}`;
            }
            if (prefix === "genetic_marker") {
              return `/marker?accession=${id}`;
            }
            if (prefix === "array_element") {
              return `/array_element?accession=${id}`;
            }
            if (prefix === "locus") {
              return `/locus?accession=${id}`;
            }
          }
        }
        return "/error"; // Fallback to error if no valid accession
      },
    },
  ],
  "/servlets/processor": [
    {
      type: 'transposonfamily',
      update_action: 'browse',
      targetPath: () => "/browse/transposon_families",
    },
    {
      type: 'genesymbol',
      update_action: 'view_symbol',
      targetPath: () => "/browse/gene_symbols",
    }
  ],
  "/servlets/Search": [
    {
      action: "new_search",
      type: "gene",
      targetPath: () => "/search/genes",
    },
    {
      action: "new_search",
      type: "dna",
      targetPath: () => "/search/dna",
    },
    {
      action: "new_search",
      type: "ecotype",
      targetPath: () => "/search/ecotype",
    },
    {
      action: "new_search",
      type: "keyword",
      targetPath: () => "/search/keyword",
    },
    {
      action: "new_search",
      type: "marker",
      targetPath: () => "/search/markers",
    },
    {
      search_action: "new_search",
      type: "expr",
      targetPath: () => "/browse/microarray_experiments",
    },
    {
      action: "new_search",
      type: "expression",
      targetPath: () => "/pagenotfound", // special handling needed, maybe redirect to a contact page
    },
    {
      action: "new_search",
      type: "community",
      targetPath: () => "/search/community",
    },
    {
      action: "new_search",
      type: "polyallele",
      targetPath: () => "/search/polyallele",
    },
    {
      action: "new_search",
      type: "protein",
      targetPath: () => "/search/protein",
    },
    {
      action: "new_search",
      type: "protocol",
      targetPath: () => "/search/protocol",
    },
    {
      action: "new_search",
      type: "publication",
      targetPath: () => "/search/publication",
    },
    {
      type: 'publication',
      action: 'search',
      pageNum: '1',
      recent: '14',
      size: '500',
      sort: 'journal',
      targetPath: () => "/browse/recent_literature",
    },
    {
      action: "new_search",
      type: "germplasm",
      targetPath: () => "/search/germplasm",
    },
    {
      action: "new_search",
      type: "transposon",
      targetPath: () => "/search/transposon",
    },
  ],
  "entity/person_detail": [
    {
      type: "person_detail",
      targetPath: (id) => `/person?key=${id}`,
    },
  ],
  "entity/organization_detail": [
    {
      type: "organization_detail",
      targetPath: (id) => `/organization?key=${id}`,
    },
  ],
  "/news/jobs.jsp": [
    {
      targetPath: () => `/news/jobs`,
    },
  ],
  "/news/events.jsp": [
    {
      targetPath: () => `/news/events`,
    },
  ],
  "/download_files/Genes/Unknown_Gene_Lists/UnknownGeneLists_README.txt": [
    {
      targetPath: () => '/download/file?path=Genes/Unknown_Gene_Lists/UnknownGeneLists_README.txt',
    },
  ],
  "/download_files/Genes/TAIR8_genome_release/tair8_gff3/Methylation_GFF/mCIP_ddc_col_BU.gff": [
    {
      targetPath: () => '/download/file?path=Genes/TAIR8_genome_release/tair8_gff3/Methylation_GFF/mCIP_ddc_col_BU.gff',
    },
  ],
  "/browse/genefamily/index.jsp": [
    {
      targetPath: () => "/browse/gene_family",
    },
  ],
  "/portals/nomenclature/symbol_main.jsp": [
    {
      targetPath: () => "/submit/gene_class_symbol_registration",
    },
  ],
  "/download/index.jsp": [
    {
      targetPath: () => '/download/overview',
    },
  ],
  "/download/index-auto.jsp": [
    {
      targetPath: (from) => {
        // Extracting the 'dir' value from the object
        const dirObject = from && from.query && from.query.dir ? from.query.dir : {};
        const dir = dirObject.dir ? decodeURIComponent(dirObject.dir) : '';

        switch (from.dir) {
          case '/download_files/Genes':
            return '/download/list?dir=Genes';
          case '/download_files/GO_and_PO_Annotations':
            return '/download/list?dir=GO_and_PO_Annotations';
          case '/download_files/GO_and_PO_Annotations/Gene_Ontology_Annotations':
            return '/download/list?dir=GO_and_PO_Annotations%2FGene_Ontology_Annotations';
          default:
            return '/pagenotfound'; // Redirect to a 'Page Not Found' component if 'dir' is incorrect or missing
        }
      },
    },
  ],
  "/submit/index.jsp": [
    {
      targetPath: () => '/submit/overview',
    },
  ],
};

export default redirectMapping;
