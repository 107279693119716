import { getInstitutionByIP } from "@/services/SubscriptionService.js";

export const subscriptionStatus = {
  namespaced: true,
  state: {
    institution: null,
    status: null,
    loaded: false, // to track if the data has been loaded
  },
  getters: {
    institution: (state) => state.institution,
    status: (state) => state.status,
    // enable status for local detail page test and other things without logging in
    // status: (state) => "subscribed",
    isLoaded: (state) => state.loaded,
  },
  mutations: {
    setInstitution(state, payload) {
      state.institution = payload.institution;
      state.status = payload.status;
      state.loaded = true;
    },
    resetInstitution(state) {
      state.institution = null;
      state.status = null;
      state.loaded = false;
    },
  },
  actions: {
    async fetchInstitution({ commit, state }) {
      //   if (state.institution !== null) return;
      let response = await getInstitutionByIP();
      if (response.length === 0) {
        commit("resetInstitution");
      } else {
        commit("setInstitution", {
          institution: response.name,
          status: response.status,
        });
      }
    },
  },
};
