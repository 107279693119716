<template>
  <div class="star-container ml-2 mb-2">
    <img src="@/assets/diamond.svg" height="20px" alt="Diamond" />
    <span class="ml-2">Premium Page</span>
  </div>
</template>

<script>
export default {
  name: "PremiumIndicator",
};
</script>
