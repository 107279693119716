<template>
  <div id="app">
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"
    />
    <div class="header" v-if="$route.name !== 'Sequences'">
      <div class="top d-flex">
        <div class="p-2 d-flex">
          <router-link to="/" class="cursor-pointer">
            <img
              src="https://s3-us-west-2.amazonaws.com/pw2-logo/tair_logo_highdef_originalcolors.png"
              width="80"
              height="80"
              alt="tair"
              class="imgCont"
            />
          </router-link>
        </div>
        <div
          class="d-flex flex-column align-items-center align-self-center w-100"
        >
          <div id="nav" class="self-center d-flex align-items-center">
            <div>
              <router-link to="/">Home</router-link> |
              <router-link to="/help">Help</router-link> |
              <router-link to="/contact">Contact</router-link> |
              <router-link to="/about/overview">About Us</router-link> |
              <a href="https://phoenixbioinfo.org/tair/#Pricing" target="_blank"
                >Subscribe</a
              >
              |
              <span v-if="!getLoggedIn">
                <router-link to="/login">Login</router-link> |
                <router-link v-if="!getLoggedIn" to="/register"
                  >Register</router-link
                >
              </span>
              <span v-if="getLoggedIn">
                <router-link to="/auth/logout">Logout</router-link> |
                <router-link v-if="getLoggedIn" to="/profile">
                  <i class="fas fa-user"></i> Profile
                </router-link>
              </span>
              <span
                class="ml-4"
                v-if="this.institution()"
                style="font-size: 15px; font-style: italic"
              >
                <b>Institution: {{ this.institution() }}</b>
                <span class="ml-2">({{ this.status() }})</span>
              </span>
            </div>
          </div>
          <GeneralSearch />
        </div>
      </div>

      <div class="bottom" id="nav">
        <Navbar />
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>

    <!-- <footer class="footer" v-if="$route.name !== 'Sequences'">

      <div class="footer-content">
        <div class="footer-section left">
          Questions or comments?<a href="mailto:curator@arabidopsis.org" class="link"><i class="fas fa-envelope"></i> curator@arabidopsis.org</a>
        </div>
        <div class="footer-section center">
          <a href="https://phoenixbioinformatics.atlassian.net/wiki/spaces/COM/pages/42217301/Release+Notes" class="link release-notes">
            <i class="fas fa-file-alt"></i> TAIR Release Notes
          </a>
        </div>
        <div class="footer-section right">
          <a href="http://www.phoenixbioinformatics.org/" target="_blank" class="phoenix-logo">
            <img src="@/public/phoenix-logo.png" alt="Phoenix Bioinformatics logo" />
          </a>
        </div>
      </div>

    </footer> -->

    <footer class="footer" v-if="$route.name !== 'Sequences'">
      <div class="footer-content">
        <!-- Left Section -->
        <div class="footer-section left">
          <div class="contact-line">
            Questions or comments? 
            <a href="mailto:curator@arabidopsis.org" class="link">
              <i class="fas fa-envelope"></i> curator@arabidopsis.org
            </a>
          </div>
          <div class="social-links">
            <a href="https://www.facebook.com/tairnews" target="_blank" class="social-icon">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCqYlBTnoVz6s1beB058lBqQ" target="_blank" class="social-icon">
              <i class="fab fa-youtube"></i>
            </a>
            <a href="https://bsky.app/profile/tairnews.bsky.social" target="_blank" class="social-icon">
              <i class="fa-brands fa-bluesky"></i>
            </a>
            <a href="https://www.linkedin.com/company/phoenix-bioinformatics" target="_blank" class="social-icon">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>

        <!-- Center Section -->
        <div class="footer-section center">
          <a
            href="https://phoenixbioinformatics.atlassian.net/wiki/spaces/COM/pages/42217301/Release+Notes"
            class="link release-notes"
          >
            <i class="fas fa-file-alt"></i> TAIR Release Notes
          </a>
        </div>

        <!-- Right Section -->
        <div class="footer-section right">
          <a
            href="http://www.phoenixbioinformatics.org/"
            target="_blank"
            class="phoenix-logo"
          >
            <img
              src="@/public/phoenix-logo.png"
              alt="Phoenix Bioinformatics logo"
            />
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

.header {
  background-color: #bbc9ce;
}

.content {
  min-height: 76%;
}

.footer {
  background-color: #bbc9cd;
  color: #2c3e50;
  padding: 20px;
  font-size: 0.9rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-section {
  flex: 5;
  padding: 10px;
  text-align: center;
}

.footer-section.left {
  text-align: left;
}

.footer-section.right {
  text-align: right;
}

.contact-line {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  gap: 15px;
}

.email-link {
  color: #2c3e50;
  text-decoration: none;
}

.email-link:hover {
  color: #3498db;
}

.link {
  color: #2c3e50;
  text-decoration: none;
  margin: 0 15px;
}

.link:hover {
  color: #3498db;
}

.social-icon {
  color: #2c3e50;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }
}

.phoenix-logo img {
  height: 50px;
  transition: transform 0.3s ease;
}

.phoenix-logo img:hover {
  transform: scale(1.1);
}

.email-link:hover {
  color: #3498db;
}

.link {
  color: #2c3e50;
  text-decoration: none;
  margin: 0 15px;
}

.link:hover {
  color: #3498db;
}

.phoenix-logo img {
  height: 50px;
  transition: transform 0.3s ease;
}

.phoenix-logo img:hover {
  transform: scale(1.1);
}

// .footer {
//   border-top: 1px solid #e7e7e7;
//   height: 3%;
// }

// .footer-content {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 5px; /* Adjust padding as needed */
// }

// .right {
//   display: flex;
//   align-items: center;
//   gap: 24px;
// }

// a {
//   color: #1f69c4;
//   text-decoration: none;
// }

.contact-info {
  font-size: 15px;
  padding-left: 10px;
}

.phoenix-logo img {
  width: 69px;
  height: 100%;
}

#nav {
  padding: 10px;
  @media (max-width: 480px) {
    padding: 5px;
  }
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #1d6100;
      background-color: #ffffff;
      padding: 2px;
      border-radius: 5px;
      box-shadow: 0 0 0 1px #1d6100;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}
.custom-navbar .b-nav-item-dropdown:hover,
.custom-navbar .b-nav-item-dropdown.menu-active {
  background-color: #f0f0f0; /* Change this color to your desired highlight color */
}

a {
  color: #0052a3;
}
hr {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}
.btn-primary {
  color: black;
  font-weight: bold;
}
.breadcrumb-item.active {
  color: black;
}
.page-link {
  color: #0052a3;
}
.page-item.active .page-link {
  background-color: #0052a3;
}
.highlight-term {
  color: #9500a3;
}
.highlight-text {
  color: #d30159;
}
.btn-info {
  color: black;
}
.detail-wrapper {
  height: 100%;
  font-size: 14px;

  display: flex;
  .side-wrapper {
    width: 12%;
    height: 100%;
    text-align: left;

    .side-item {
      background-color: #f5f7f5;
      @media (max-width: 480px) {
        font-size: 12px;
        padding: 5px;
        padding-right: 50px;
      }
    }

    .selected {
      background-color: #c3ce7e;
    }
  }
  @media (max-width: 768px) {
    .side-wrapper {
      display: none;
    }
  }
  .list-wrapper {
    width: 88%;
    height: 75vh;
    overflow-y: scroll;
  }
  @media (max-width: 768px) {
    .list-wrapper {
      width: 100%;
    }
  }
}
.overview-wrapper {
  display: flex;
  background-color: #f1efec;
  border: 1px solid #e0e0e0;
  padding: 20px;
  .breadcrumb {
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
  .side-wrapper {
    width: 20%;
    min-width: 160px;
    border-right: 1px solid #e0e0e0;
    padding-right: 20px;

    .side-item {
      text-align: left;
      padding: 10px 10px;
      border-bottom: 1px solid #e0e0e0;
      font-weight: bold;
      font-size: 20px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .overview-wrapper {
    padding: 0;
    .side-wrapper {
      display: none;
    }
  }
}
.searchWrapper {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 5px;
  .pageTop {
    // background-color: antiquewhite;
    // margin: 20px;
    @media screen and (max-width: 300px) {
    }
    @media screen and (min-width: 900px) {
      //   height: 25%;
    }
  }
  .pageMain {
    background-color: #f1efec;
  }
}
.content-wrapper {
  width: 100%;
  padding-left: 20px;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    color: #495057;
  }
  p {
    margin-bottom: 20px;
    text-align: left;
  }
  .items-panel {
    text-align: left;
    background-color: #f7f6f3;
    padding: 10px;
    .download-item {
      margin-bottom: 30px;
      h3 {
        color: #2a568f;
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 10px;
        text-align: left;
      }
      p {
        margin-left: 20px;
        text-align: left;
      }
    }
    .news-item {
      margin-bottom: 30px;
      h3 {
        color: #2a568f;
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 10px;
        text-align: left;
      }
      p {
        margin-left: 20px;
        text-align: left;
      }
      .news-header {
        color: #2a568f;
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 10px;
        text-align: left;
      }
    }
  }
  .options-panel {
    background-color: #f7f6f3;
    padding: 10px;
    .search-option {
      margin-bottom: 30px;
      h3 {
        color: #2a568f;
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 10px;
        text-align: left;
      }
      p {
        margin-left: 20px;
        text-align: left;
      }
    }
  }
  .pg-link {
    cursor: pointer;
  }
  .pg-link:hover {
    background: #feffbb;
  }
}
@media (max-width: 768px) {
  .content-wrapper {
    padding-left: 5px;
  }
  .footer-section.left {
    text-align: center;
  }

  .contact-line {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .social-links {
    justify-content: center;
  }
}
.custom-tooltip {
  max-width: 300px;
  max-height: 100px;
  overflow-y: auto;
}
.star {
  color: #ffd700;
  font-size: 24px;
  font-style: normal;
  cursor: help;
}
.star-container {
  position: relative;
  display: inline-block;
}
</style>

<script>
import Navbar from "@/views/Navbar";
import GeneralSearch from "@/views/search/GeneralSearch.vue";
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import Api from "@/services/Api";

export default {
  name: "TairHome",
  components: {
    Navbar,
    GeneralSearch,
  },
  data() {
    return {
      searchText: null,
      lastSearchText: null,
      searchTypes: ["Gene"],
      selectedSearchType: "Gene",
      searchAlreadyShown: false,
      //   institution: null,
      subscribeStatus: "Subscribed",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("authProfile", ["getLoggedIn"]),
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.cookieInterval);
  },
  mounted() {
    this.cookieInterval = setInterval(() => {
      this.checkCookies();
    }, 5000);
    console.log("Is user logged in:", this.getLoggedIn); // Logs the value when the component is mounted
    // load auth profile on application mount, but avoid duplicated dispatch
    if (this.$route.path !== "/auth/login") {
      this.$store.dispatch("authProfile/loadAuthProfile");
      this.$store.dispatch("subscriptionStatus/fetchInstitution");
      this.init();
      this.loading = false;
    }
  },
  watch: {
    getLoggedIn(newValue) {
      console.log("loggedIn changed:", newValue); // Logs whenever loggedIn changes
    },
  },
  methods: {
    ...mapGetters("authProfile", ["getLoginUrl"]),
    ...mapGetters("subscriptionStatus", ["institution", "status"]),
    async init() {},
    goSearch() {
      if (this.searchText && this.searchText !== "") {
        // this.lastSearchText = this.searchText;
        this.$nextTick(() => {
          if (this.$route.path !== "/results") {
            this.searchAlreadyShown = false;
          }
          this.$router.push({
            path: "/results",
            query: {
              mainType: "general",
              searchText: this.searchText,
              category: "genes",
            },
          });
          this.searchText = "";
        });
      }
    },
    async checkCookies() {
      const credentialId = Cookies.get("credentialId");
      const secretKey = Cookies.get("secretKey");

      // If cookies are not present, update the Vuex state to logged out
      if (!credentialId || !secretKey) {
        try {
          // Update the loggedIn state to false
          this.$store.commit("authProfile/UPDATE_STATE", { loggedIn: false });
          // Call the invalidate action to clear session-related data
          await this.$store.dispatch("authProfile/invalidate");
          // Make an API call to logout the user from the backend
          const response = await Api().get("auth/logout");
          // Clear the interval
          clearInterval(this.cookieInterval);
          if (this.$route.path === "/profile") {
            this.$router.push("/");
          }
        } catch (error) {
          console.error("Error while checking cookies and logging out:", error);
        }
      }
    },
  },
};
</script>
