<template>
  <div class="items-panel">
    <div v-for="(item, key) in items" :key="item.name" class="download-item">
      <div v-if="item.type == 'dir'" class="folder-display">
        <span class="icon-text-wrapper">
          <i class="fa-solid fa-folder"></i>
          <a :href="decodeURIComponent(item.path)"
            ><h3>{{ item.name }}</h3></a
          >
        </span>
      </div>
      <div v-else class="file-display">
        <span class="icon-text-wrapper">
          <i class="fa-solid fa-file"></i>
          <a :href="'file?path=' + decodeURIComponent(item.path)">{{
            item.name
          }}</a>
        </span>
        <span class="item-metadata">{{ item.size }}</span>
        <span class="item-metadata">{{ item.lastModified }}</span>
        <span class="icon-text-wrapper">
          <a
            :href="
              'file?path=' +
              decodeURIComponent(item.path) +
              '&force_download=true'
            "
            title="Download file"
          >
            <i class="fa-solid fa-download"></i>
          </a>
          <a
            href="#"
            @click.prevent="copyDownloadPath(item.path)"
            title="Copy download link"
          >
            <i class="fa-solid fa-copy ml-2"></i>
          </a>
          <div
            v-if="shouldShowPremiumIcon(item.name)"
            class="star-container ml-2 mb-2"
          >
            <img src="@/assets/diamond.svg" height="20px" alt="Diamond" />
          </div>
        </span>
      </div>
    </div>
    <div v-if="loaded && (!items || items.length == 0)">
      Cannot load directory content. Please try again later or contact
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </div>
  </div>
</template>

<script>
import { getDownloadLink } from "@/services/DownloadService";
import { listDirContent } from "@/services/DownloadService";

export default {
  name: "DownloadList",
  data() {
    return {
      items: [],
      loaded: false,
    };
  },
  mounted() {
    this.fetchItems();
  },
  watch: {
    // Reactively watch for route changes, including query params
    "$route.query.dir": {
      immediate: true,
      async handler(newDir, oldDir) {
        await this.fetchItems();
      },
    },
  },
  methods: {
    shouldShowPremiumIcon(fileName) {
      const ignoredFiles = ["README.txt", ".DS_Store"];
      const currentDir = this.$route.query.dir || "";
      return (
        currentDir.includes("Subscriber_Data_Releases") &&
        !ignoredFiles.includes(fileName)
      );
    },
    async fetchItems() {
      if (this.$route.query.dir) {
        const items = await listDirContent(this.$route.query.dir);
        if (!items || items.length == 0) {
          // do nothing
        } else {
          this.items = items;
        }
      }
      this.loaded = true;
    },
    getDownloadLink(filePath) {
      return getDownloadLink(filePath);
    },
    copyDownloadPath(filePath) {
      const downloadLink = getDownloadLink(decodeURIComponent(filePath));
      navigator.clipboard
        .writeText(downloadLink)
        .then(() => {
          // You could add a toast notification here if desired
          console.log("Download link copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy download link:", err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  .items-panel {
    .item-metadata {
      margin-left: 10px;
    }
    .margin-right {
      margin-right: 10px;
    }
    .file-display {
      display: flex;
    }
    .icon-text-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      i {
        margin-right: 10px;
      }
      .fa-folder {
        padding-bottom: 10px;
      }
      .fa-file {
        padding-bottom: 3px;
      }
      .fa-download {
        margin-left: 10px;
      }
      .fa-copy {
        cursor: pointer;
      }
    }
  }
}
</style>
