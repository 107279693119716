<template>
  <div class="m-2">
    <div>
      <div class="mainheader d-flex justify-content-left p-2">
        <span>TAIR BLAST 2.9.0+</span>
        <img
          src="@/assets/diamond.svg"
          height="20px"
          alt="Premium"
          class="ml-2"
        />
      </div>

      <div class="d-flex justify-content-left">
        <p class="pl-2 text-left">
          <b>
            This form uses
            <a
              href="https://blast.ncbi.nlm.nih.gov/Blast.cgi?CMD=Web&PAGE_TYPE=BlastDocs"
              target="_blank"
              >NCBI BLAST 2.9.0+</a
            >

            <br />
            <span
              >View help documentation
              <a
                href="https://conf.phoenixbioinformatics.org/display/COM/TAIR+BLAST+Help"
                target="_blank"
                >here</a
              ></span
            >
          </b>
        </p>
      </div>
    </div>
    <div class="container p-2 border rounded shadow-sm form-bg">
      <fieldset id="results" class="border border-dark rounded p-2">
        <span class="pl-4 mb-3 d-flex justify-content-left">
          <b>Blast</b>
        </span>
        <div class="form-group row">
          <label for="algorithm" class="col-sm-4 col-form-label text-left"
            >BLAST™ program:</label
          >
          <div class="col-sm-4">
            <select
              id="algorithm"
              class="form-control"
              v-model="blastForm.algorithm"
              @change="updateDbOptions"
              required
            >
              <option value="" disabled selected>Select your option</option>
              <option
                v-for="option in algorithmOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="default_db" class="col-sm-4 col-form-label text-left"
            >Datasets:</label
          >
          <div class="col-sm-4">
            <select
              id="default_db"
              class="form-control"
              v-model="blastForm.default_db"
              required
            >
              <option
                v-for="(option, index) in dbOptions"
                :key="`db-${index}-${option.value}`"
                :value="option.value"
                :disabled="option.disabled"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label text-left">Input Type:</label>
          <div class="col-sm-4 d-flex align-items-center">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inputType"
                id="querySequence"
                value="seq"
                v-model="blastForm.textbox"
                required
              />
              <label class="form-check-label" for="querySequence"
                >Query Sequence</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inputType"
                id="locusName"
                value="locus"
                v-model="blastForm.textbox"
                required
              />
              <label class="form-check-label text-left pl-2" for="locusName"
                >Locus Name (At1g01030)</label
              >
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputText" class="col-sm-4 col-form-label text-left"
            >Input:</label
          >
          <div class="col-sm-4">
            <textarea
              id="inputText"
              class="form-control"
              v-model="blastForm.QueryText"
              rows="4"
              placeholder="Enter your query sequence or locus name here"
              required
            ></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputText" class="col-sm-4 col-form-label text-left"
            >Choose a file:</label
          >
          <div class="col-sm-4 d-flex align-items-center">
            <input
              type="file"
              id="fileInput"
              @change="handleFileUpload"
              aria-label="fileInput"
              style="display: none"
            />
            <b-button size="sm" @click="triggerFileInputClick" class="mt-2"
              >Choose File</b-button
            >
            <span class="ml-2">{{ chosenFilename }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputText" class="col-sm-4 col-form-label text-left"
            >Raw, FASTA, GCG and RSF formats accepted.</label
          >
        </div>
        <div class="form-group row">
          <div class="col-sm-4 form-check form-check-inline pl-4">
            <input
              class="form-check-input"
              type="checkbox"
              name="inputType"
              id="filterQuery"
              value="locus"
              v-model="blastForm.QueryFilter"
              required
            />
            <label class="form-check-label text-left pl-2" for="filterQuery"
              >Filter Query</label
            >
          </div>
        </div>
      </fieldset>
      <fieldset id="advanced" class="border border-dark rounded p-2 mt-4">
        <div class="d-flex justify-content-start align-items-center pl-4 mb-3">
          <button @click="toggleAdvancedOptions">
            {{ advancedOptionsVisible ? "-" : "+" }}
          </button>
          <b class="ml-4">Advanced BLAST™ Parameter Options</b>
        </div>
        <div v-if="advancedOptionsVisible">
          <div class="form-group row">
            <label for="matrix" class="col-sm-4 col-form-label text-left"
              >Weight Matrix:</label
            >
            <div class="col-sm-4">
              <select
                name="Matrix"
                id="matrix"
                class="form-control"
                v-model="blastForm.Matrix"
              >
                <option
                  v-for="option in matrixOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="maxScores" class="col-sm-4 col-form-label text-left"
              >Max Scores:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="MaxScores"
                id="maxScores"
                class="form-control"
                v-model="blastForm.MaxScores"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="maxAlignments" class="col-sm-4 col-form-label text-left"
              >Max Alignments:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="MaxAlignments"
                id="maxAlignments"
                class="form-control"
                v-model="blastForm.MaxAlignments"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="gappedAlignments"
              class="col-sm-4 col-form-label text-left"
              >Gapped Alignments:</label
            >
            <div class="col-sm-4">
              <select
                name="GappedAlignment"
                id="gappedAlignments"
                class="form-control"
                v-model="blastForm.GappedAlignment"
              >
                <option
                  v-for="option in gappedAlignmentOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="expectation" class="col-sm-4 col-form-label text-left"
              >Expectation:</label
            >
            <div class="col-sm-4">
              <select
                name="Expectation"
                id="expectation"
                class="form-control"
                v-model="blastForm.Expectation"
              >
                <option
                  v-for="option in expectationOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="nucleicMismatch"
              class="col-sm-4 col-form-label text-left"
              >Nucleic Mismatch:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="NucleicMismatch"
                id="nucleicMismatch"
                class="form-control"
                v-model="blastForm.NucleicMismatch"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="nucleicMatch" class="col-sm-4 col-form-label text-left"
              >Nucleic Match:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="NucleicMatch"
                id="nucleicMatch"
                class="form-control"
                v-model="blastForm.NucleicMatch"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="openPenalty" class="col-sm-4 col-form-label text-left"
              >Gap Opening Penalty:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="OpenPenalty"
                id="openPenalty"
                class="form-control"
                v-model="blastForm.OpenPenalty"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="extendPenalty" class="col-sm-4 col-form-label text-left"
              >Gap Extension Penalty:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="ExtendPenalty"
                id="extendPenalty"
                class="form-control"
                v-model="blastForm.ExtendPenalty"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="extensionThreshold"
              class="col-sm-4 col-form-label text-left"
              >Extension Threshold:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="ExtensionThreshold"
                id="extensionThreshold"
                class="form-control"
                v-model="blastForm.ExtensionThreshold"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="wordSize" class="col-sm-4 col-form-label text-left"
              >Word Size:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                name="WordSize"
                id="wordSize"
                class="form-control"
                v-model="blastForm.WordSize"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="queryGeneticCode"
              class="col-sm-4 col-form-label text-left"
              >Query and Database Genetic Code:</label
            >
            <div class="col-sm-4">
              <select
                name="QueryGeneticCode"
                id="queryGeneticCode"
                class="form-control"
                v-model="blastForm.QueryGeneticCode"
              >
                <option
                  v-for="option in geneticCodeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset id="results" class="border border-dark rounded p-2 mt-4">
        <span class="pl-4 mb-3 d-flex justify-content-left">
          <b>Results Return Options</b>
        </span>
        <div class="form-group row">
          <label for="comment" class="col-sm-4 col-form-label text-left"
            >Output Title:</label
          >
          <div class="col-sm-4">
            <input
              type="text"
              name="Comment"
              id="comment"
              class="form-control"
              v-model="blastForm.Comment"
            />
          </div>
        </div>
        <!-- <div class="form-group row">
          <label for="email" class="col-sm-4 col-form-label text-left"
            >Email:</label
          >
          <div class="col-sm-4">
            <input
              type="text"
              name="ReplyTo"
              id="email"
              class="form-control"
              v-model="blastForm.ReplyTo"
            />
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <label for="returnResults" class="col-sm-4 col-form-label text-left"
            >Return Results:</label
          >
          <div class="col-sm-4">
            <select
              name="ReplyVia"
              id="returnResults"
              class="form-control"
              v-model="blastForm.ReplyVia"
            >
              <option
                v-for="option in returnResultsOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="form-group row">
          <label for="format" class="col-sm-4 col-form-label text-left"
            >Result Format:</label
          >
          <div class="col-sm-4">
            <select
              name="ReplyFormat"
              id="format"
              class="form-control"
              v-model="blastForm.ReplyFormat"
            >
              <option
                v-for="option in formatOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="text-left pb-2">
          <span
            >Please be patient. Replies directly to your browser may take a
            minute or two. <br />
            Selecting an e-mail reply allows you to immediately request more
            searches.</span
          >
        </div>
      </fieldset>
      <div class="form-group row">
        <div class="col-sm-4 offset-sm-1 d-flex justify-content-left mt-2">
          <button type="button" class="btn btn-primary" @click="handleSubmit">
            Run BLAST
          </button>
          <button
            type="button"
            class="ml-4 btn btn-secondary"
            @click="handleReset"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    blastForm: {
      algorithm: "blastn",
      default_db: "Araport11_3_utr_20220914",
      BlastTargetSet: "Araport11_3_utr_20220914",
      textbox: "seq",
      QueryText: "",
      Matrix: "",
      MaxScores: 100,
      MaxAlignments: 50,
      NucleicMismatch: -3,
      NucleicMatch: 2,
      OpenPenalty: "0 (use default)",
      ExtendPenalty: "0 (use default)",
      ExtensionThreshold: "0 (use default)",
      WordSize: "0 (use default)",
      Expectation: "10",
      QueryGeneticCode: "1",
      GappedAlignment: "T",
      Comment: "optional, will be added to output for your use",
      ReplyTo: "",
      ReplyFormat: "HTML",
      ReplyVia: "BROWSER",
      QueryFilter: true,
    },
    advancedOptionsVisible: false,
    algorithmOptions: [
      { text: "BLASTN: NT query, NT db", value: "blastn" },
      { text: "BLASTP: AA query, AA db", value: "blastp" },
      { text: "BLASTX: NT query, AA db", value: "blastx" },
      { text: "TBLASTN: AA query, NT db", value: "tblastn" },
      { text: "TBLASTX: NT query, NT db (All 6 Frames)", value: "tblastx" },
    ],
    dbOptions: [],
    matrixOptions: [
      { value: "blosum45", text: "Blosum45" },
      { value: "blosum50", text: "Blosum50" },
      { value: "blosum62", text: "Blosum62" },
      { value: "blosum80", text: "Blosum80" },
      { value: "blosum90", text: "Blosum90" },
      { value: "pam30", text: "Pam30" },
      { value: "pam70", text: "Pam70" },
      { value: "pam250", text: "Pam250" },
    ],
    expectationOptions: [
      { value: "0.0001", text: "0.0001" },
      { value: "0.001", text: "0.001" },
      { value: "0.01", text: "0.01" },
      { value: "0.1", text: "0.1" },
      { value: "1", text: "1" },
      { value: "10", text: "10" },
      { value: "100", text: "100" },
      { value: "1000", text: "1000" },
    ],
    geneticCodeOptions: [
      { value: "1", text: "Standard or Universal (default)" },
      { value: "2", text: "Vertebrate Mitochondrial" },
      { value: "3", text: "Yeast Mitochondrial" },
      { value: "4", text: "Mold Mitochondrial" },
      { value: "5", text: "Invertebrate Mitochondrial" },
      { value: "6", text: "Ciliate Nuclear" },
      { value: "9", text: "Echinoderm Mitochondrial" },
      { value: "10", text: "Euplotid Nuclear" },
      { value: "11", text: "Bacterial" },
      { value: "12", text: "Alternative Yeast Nuclear" },
      { value: "13", text: "Ascidian Mitochondrial" },
      { value: "14", text: "Flatworm Mitochondrial" },
      { value: "15", text: "Blepharisma Macronuclear" },
    ],
    gappedAlignmentOptions: [
      { value: "T", text: "Yes" },
      { value: "F", text: "No" },
    ],
    returnResultsOptions: [
      { value: "BROWSER", text: "To your web browser" },
      { value: "EMAIL-MESSAGE", text: "By Email message" },
    ],
    formatOptions: [
      { value: "HTML", text: "HTML hypertext" },
      { value: "TEXT", text: "Normal text" },
    ],
    chosenFilename: "No file chosen",
  }),
  created() {
    this.updateDbOptions();
    const sequenceType = this.$route.query.sequence_type;
    const sequence = this.$route.query.sequence;

    this.blastForm.textbox = "seq";
    if (sequence) {
      this.blastForm.QueryText = sequence;
    }

    if (sequenceType == "sequence") {
      setTimeout(() => {
        this.blastForm.default_db = "Araport11_cdna_20220914";
        this.blastForm.BlastTargetSet = "Araport11_cdna_20220914";
      }, 500);
    } else if (sequenceType == "protein") {
      setTimeout(() => {
        this.blastForm.algorithm = "blastp";
        this.updateDbOptions();
        // this.blastForm.default_db = 'Araport11_pep_20220914';
        // this.blastForm.BlastTargetSet = 'Araport11_pep_20220914';
      }, 500);
    }
  },
  mounted() {},
  methods: {
    handleSubmit() {
      const actionUrl = `${process.env.VUE_APP_TOOLS_BASE_URL}/cgi-bin/Blast/TAIRblast.pl`;
      const method = "POST";
      const target = "_blank";
      const fields = [
        { type: "hidden", name: "Algorithm", value: this.blastForm.algorithm },
        {
          type: "hidden",
          name: "default_db",
          value: this.blastForm.default_db,
        },
        {
          type: "hidden",
          name: "BlastTargetSet",
          value: this.blastForm.default_db,
        },
        {
          type: "hidden",
          name: "textbox",
          value: this.blastForm.textbox,
        },
        {
          type: "hidden",
          name: "QueryText",
          value: this.blastForm.QueryText,
        },
        {
          type: "hidden",
          name: "QueryFilter",
          value: this.blastForm.QueryFilter == true ? "T" : "F",
        },
        {
          type: "hidden",
          name: "ReplyFormat",
          value: this.blastForm.ReplyFormat,
        },
        { type: "hidden", name: "Comment", value: this.blastForm.Comment },
        { type: "hidden", name: "ReplyTo", value: this.blastForm.ReplyTo },
        {
          type: "hidden",
          name: "GappedAlignment",
          value: this.blastForm.GappedAlignment,
        },
        { type: "hidden", name: "ReplyVia", value: this.blastForm.ReplyVia },
        { type: "hidden", name: "Matrix", value: this.blastForm.Matrix },
        { type: "hidden", name: "MaxScores", value: this.blastForm.MaxScores },
        {
          type: "hidden",
          name: "MaxAlignments",
          value: this.blastForm.MaxAlignments,
        },
        {
          type: "hidden",
          name: "NucleicMismatch",
          value: this.blastForm.NucleicMismatch,
        },
        {
          type: "hidden",
          name: "NucleicMatch",
          value: this.blastForm.NucleicMatch,
        },
        {
          type: "hidden",
          name: "OpenPenalty",
          value: this.blastForm.OpenPenalty,
        },
        {
          type: "hidden",
          name: "ExtendPenalty",
          value: this.blastForm.ExtendPenalty,
        },
        {
          type: "hidden",
          name: "ExtensionThreshold",
          value: this.blastForm.ExtensionThreshold,
        },
        { type: "hidden", name: "WordSize", value: this.blastForm.WordSize },
        {
          type: "hidden",
          name: "Expectation",
          value: this.blastForm.Expectation,
        },
        {
          type: "hidden",
          name: "QueryGeneticCode",
          value: this.blastForm.QueryGeneticCode,
        },
      ];
      // console.log(fields);
      this.submitForm(actionUrl, method, target, fields);
    },
    handleReset() {
      this.blastForm = {
        algorithm: "blastn",
        default_db: "Araport11_3_utr_20220914",
        BlastTargetSet: "Araport11_3_utr_20220914",
        textbox: "seq",
        QueryText: "",
        QueryFilter: true,
        Matrix: "blosum80",
        MaxScores: 100,
        MaxAlignments: 50,
        NucleicMismatch: -3,
        NucleicMatch: 2,
        OpenPenalty: "0 (use default)",
        ExtendPenalty: "0 (use default)",
        ExtensionThreshold: "0 (use default)",
        WordSize: "0 (use default)",
        Expectation: "10",
        QueryGeneticCode: "1",
        GappedAlignment: "T",
        Comment: "optional, will be added to output for your use",
        ReplyTo: "",
        ReplyFormat: "HTML",
        ReplyVia: "BROWSER",
      };
      this.updateDbOptions();
    },
    updateDbOptions() {
      if (
        this.blastForm.algorithm == "tblastn" ||
        this.blastForm.algorithm == "blastp"
      ) {
        this.blastForm.Matrix = "blosum62";
      } else {
        this.blastForm.Matrix = "";
      }
      const optionsMap = {
        blastn: [
          {
            text: "Araport11 3' UTRs (DNA)",
            value: "Araport11_3_utr_20220914",
          },
          {
            text: "Araport11 5' UTRs (DNA)",
            value: "Araport11_5_utr_20220914",
          },
          {
            text: "Araport11 transcripts (DNA)",
            value: "Araport11_cdna_20220914",
          },
          {
            text: "Araport11 coding sequences (DNA)",
            value: "Araport11_cds_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 1000bp (DNA)",
            value: "Araport11_downstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 3000bp (DNA)",
            value: "Araport11_downstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 500bp (DNA)",
            value: "Araport11_downstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intergenic Sequences (DNA)",
            value: "Araport11_intergenic_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intron Sequences (DNA)",
            value: "Araport11_intron_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Araport11 genomic locus sequences (DNA)",
            value: "Araport11_seq_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 1000bp (DNA)",
            value: "Araport11_upstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 3000bp (DNA)",
            value: "Araport11_upstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 500bp (DNA)",
            value: "Araport11_upstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "TAIR10 Transcripts (-introns, +UTRs) (DNA)",
            value: "At_transcripts",
          },
          { text: "TAIR10 CDS (-introns, -UTRs) (DNA)", value: "ATH1_cds" },
          { text: "TAIR10 Genes (+introns, +UTRs) (DNA)", value: "ATH1_seq" },
          {
            text: "TAIR10 Whole Genome (BAC clones) (DNA)",
            value: "ATH1_bacs_con",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 500 bp (DNA)",
            value: "At_upstream_500",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 1000 bp (DNA)",
            value: "At_upstream_1000",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 3000 bp (DNA)",
            value: "At_upstream_3000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 1000 bp (DNA)",
            value: "At_downstream_1000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 3000 bp (DNA)",
            value: "At_downstream_3000",
          },
          { text: "TAIR10 Intergenic (DNA)", value: "At_intergenic" },
          { text: "TAIR10 Intron (DNA)", value: "At_intron" },
          { text: "TAIR10 5' UTRs (DNA)", value: "ATH1_5_UTR" },
          { text: "TAIR10 3' UTRs (DNA)", value: "ATH1_3_UTR" },
          { text: "----------", value: "", disabled: true },
          {
            text: "A. thaliana Insertion Flanks (DNA)",
            value: "At_flanks_DNA",
          },
          {
            text: "A. thaliana GB experimental cDNA/EST (DNA)",
            value: "At_GB_exp_tx_DNA",
          },
          {
            text: "A. thaliana GB refseq/tpa cDNA (DNA)",
            value: "At_GB_refseq_tx_DNA",
          },
          { text: "A. thaliana GB genomic (DNA)", value: "At_GB_genomic_DNA" },
          { text: "----------", value: "", disabled: true },
          {
            text: "Green plant GB experimental cDNA/EST (DNA)",
            value: "gp_GB_exp_tx_DNA",
          },
          {
            text: "Green plant GB refseq/tpa cDNA (DNA)",
            value: "gp_GB_refseq_tx_DNA",
          },
          { text: "Green plant GB genomic (DNA)", value: "gp_GB_genomic_DNA" },
        ],
        blastp: [
          { text: "----------", value: "", disabled: true },
          { text: "----------", value: "", disabled: true },
          { text: "----------", value: "", disabled: true },
          {
            text: "Araport11 protein sequences (Protein)",
            value: "Araport11_pep_20220914",
          },
          { text: "----------", value: "", disabled: true },
          { text: "TAIR10 Proteins (Protein)", value: "ATH1_pep" },
          { text: "----------", value: "", disabled: true },
          { text: "A. thaliana UniProt (Protein)", value: "At_Uniprot_prot" },
          {
            text: "A. thaliana GB derived from mRNA (Protein)",
            value: "At_GB_exp_prot",
          },
          {
            text: "A. thaliana GB refseq/tpa (Protein)",
            value: "At_GB_refseq_prot",
          },
          { text: "A. thaliana GB all (Protein)", value: "At_GB_all_prot" },
          { text: "----------", value: "", disabled: true },
          {
            text: "Green plant GB derived from mRNA (Protein)",
            value: "gp_GB_exp_prot",
          },
          {
            text: "Green plant GB refseq/tpa (Protein)",
            value: "gp_GB_refseq_prot",
          },
          { text: "Green plant GB all (Protein)", value: "gp_GB_all_prot" },
        ],
        blastx: [
          { text: "----------", value: "", disabled: true },
          { text: "----------", value: "", disabled: true },
          { text: "----------", value: "", disabled: true },
          {
            text: "Araport11 protein sequences (Protein)",
            value: "Araport11_pep_20220914",
          },
          { text: "----------", value: "", disabled: true },
          { text: "TAIR10 Proteins (Protein)", value: "ATH1_pep" },
          { text: "----------", value: "", disabled: true },
          { text: "A. thaliana UniProt (Protein)", value: "At_Uniprot_prot" },
          {
            text: "A. thaliana GB derived from mRNA (Protein)",
            value: "At_GB_exp_prot",
          },
          {
            text: "A. thaliana GB refseq/tpa (Protein)",
            value: "At_GB_refseq_prot",
          },
          { text: "A. thaliana GB all (Protein)", value: "At_GB_all_prot" },
          { text: "----------", value: "", disabled: true },
          {
            text: "Green plant GB derived from mRNA (Protein)",
            value: "gp_GB_exp_prot",
          },
          {
            text: "Green plant GB refseq/tpa (Protein)",
            value: "gp_GB_refseq_prot",
          },
          { text: "Green plant GB all (Protein)", value: "gp_GB_all_prot" },
        ],
        tblastn: [
          {
            text: "Araport11 3' UTRs (DNA)",
            value: "Araport11_3_utr_20220914",
          },
          {
            text: "Araport11 5' UTRs (DNA)",
            value: "Araport11_5_utr_20220914",
          },
          {
            text: "Araport11 transcripts (DNA)",
            value: "Araport11_cdna_20220914",
          },
          {
            text: "Araport11 coding sequences (DNA)",
            value: "Araport11_cds_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 1000bp (DNA)",
            value: "Araport11_downstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 3000bp (DNA)",
            value: "Araport11_downstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 500bp (DNA)",
            value: "Araport11_downstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intergenic Sequences (DNA)",
            value: "Araport11_intergenic_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intron Sequences (DNA)",
            value: "Araport11_intron_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Araport11 genomic locus sequences (DNA)",
            value: "Araport11_seq_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 1000bp (DNA)",
            value: "Araport11_upstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 3000bp (DNA)",
            value: "Araport11_upstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 500bp (DNA)",
            value: "Araport11_upstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "TAIR10 Transcripts (-introns, +UTRs) (DNA)",
            value: "At_transcripts",
          },
          { text: "TAIR10 CDS (-introns, -UTRs) (DNA)", value: "ATH1_cds" },
          { text: "TAIR10 Genes (+introns, +UTRs) (DNA)", value: "ATH1_seq" },
          {
            text: "TAIR10 Whole Genome (BAC clones) (DNA)",
            value: "ATH1_bacs_con",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 500 bp (DNA)",
            value: "At_upstream_500",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 1000 bp (DNA)",
            value: "At_upstream_1000",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 3000 bp (DNA)",
            value: "At_upstream_3000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 1000 bp (DNA)",
            value: "At_downstream_1000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 3000 bp (DNA)",
            value: "At_downstream_3000",
          },
          { text: "TAIR10 Intergenic (DNA)", value: "At_intergenic" },
          { text: "TAIR10 Intron (DNA)", value: "At_intron" },
          { text: "TAIR10 5' UTRs (DNA)", value: "ATH1_5_UTR" },
          { text: "TAIR10 3' UTRs (DNA)", value: "ATH1_3_UTR" },
          { text: "----------", value: "", disabled: true },
          {
            text: "A. thaliana Insertion Flanks (DNA)",
            value: "At_flanks_DNA",
          },
          {
            text: "A. thaliana GB experimental cDNA/EST (DNA)",
            value: "At_GB_exp_tx_DNA",
          },
          {
            text: "A. thaliana GB refseq/tpa cDNA (DNA)",
            value: "At_GB_refseq_tx_DNA",
          },
          { text: "A. thaliana GB genomic (DNA)", value: "At_GB_genomic_DNA" },
          { text: "----------", value: "", disabled: true },
          {
            text: "Green plant GB experimental cDNA/EST (DNA)",
            value: "gp_GB_exp_tx_DNA",
          },
          {
            text: "Green plant GB refseq/tpa cDNA (DNA)",
            value: "gp_GB_refseq_tx_DNA",
          },
          { text: "Green plant GB genomic (DNA)", value: "gp_GB_genomic_DNA" },
        ],
        tblastx: [
          {
            text: "Araport11 3' UTRs (DNA)",
            value: "Araport11_3_utr_20220914",
          },
          {
            text: "Araport11 5' UTRs (DNA)",
            value: "Araport11_5_utr_20220914",
          },
          {
            text: "Araport11 transcripts (DNA)",
            value: "Araport11_cdna_20220914",
          },
          {
            text: "Araport11 coding sequences (DNA)",
            value: "Araport11_cds_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 1000bp (DNA)",
            value: "Araport11_downstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 3000bp (DNA)",
            value: "Araport11_downstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Downstream Seq - 500bp (DNA)",
            value: "Araport11_downstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intergenic Sequences (DNA)",
            value: "Araport11_intergenic_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Intron Sequences (DNA)",
            value: "Araport11_intron_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "Araport11 genomic locus sequences (DNA)",
            value: "Araport11_seq_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 1000bp (DNA)",
            value: "Araport11_upstream_1000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 3000bp (DNA)",
            value: "Araport11_upstream_3000_20220914",
          },
          {
            text: "Araport11 Loci Upstream Seq - 500bp (DNA)",
            value: "Araport11_upstream_500_20220914",
          },
          { text: "----------", value: "", disabled: true },
          {
            text: "TAIR10 Transcripts (-introns, +UTRs) (DNA)",
            value: "At_transcripts",
          },
          { text: "TAIR10 CDS (-introns, -UTRs) (DNA)", value: "ATH1_cds" },
          { text: "TAIR10 Genes (+introns, +UTRs) (DNA)", value: "ATH1_seq" },
          {
            text: "TAIR10 Whole Genome (BAC clones) (DNA)",
            value: "ATH1_bacs_con",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 500 bp (DNA)",
            value: "At_upstream_500",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 1000 bp (DNA)",
            value: "At_upstream_1000",
          },
          {
            text: "TAIR10 Loci Upstream Sequences -- 3000 bp (DNA)",
            value: "At_upstream_3000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 1000 bp (DNA)",
            value: "At_downstream_1000",
          },
          {
            text: "TAIR10 Loci Downstream Sequences -- 3000 bp (DNA)",
            value: "At_downstream_3000",
          },
          { text: "TAIR10 Intergenic (DNA)", value: "At_intergenic" },
          { text: "TAIR10 Intron (DNA)", value: "At_intron" },
          { text: "TAIR10 5' UTRs (DNA)", value: "ATH1_5_UTR" },
          { text: "TAIR10 3' UTRs (DNA)", value: "ATH1_3_UTR" },
          { text: "----------", value: "", disabled: true },
          {
            text: "A. thaliana Insertion Flanks (DNA)",
            value: "At_flanks_DNA",
          },
          {
            text: "A. thaliana GB experimental cDNA/EST (DNA)",
            value: "At_GB_exp_tx_DNA",
          },
          {
            text: "A. thaliana GB refseq/tpa cDNA (DNA)",
            value: "At_GB_refseq_tx_DNA",
          },
          { text: "A. thaliana GB genomic (DNA)", value: "At_GB_genomic_DNA" },
          { text: "----------", value: "", disabled: true },
          {
            text: "Green plant GB experimental cDNA/EST (DNA)",
            value: "gp_GB_exp_tx_DNA",
          },
          {
            text: "Green plant GB refseq/tpa cDNA (DNA)",
            value: "gp_GB_refseq_tx_DNA",
          },
          { text: "Green plant GB genomic (DNA)", value: "gp_GB_genomic_DNA" },
        ],
      };
      this.$nextTick(() => {
        this.dbOptions = optionsMap[this.blastForm.algorithm];
        const firstNonDisabledOption = this.dbOptions.find(
          (option) => !option.disabled
        );
        if (firstNonDisabledOption) {
          this.$nextTick(() => {
            this.blastForm.default_db = firstNonDisabledOption.value;
          });
        }
      });
    },
    submitForm(actionUrl, method, target, fields) {
      // console.log(fields);
      // Create a form element
      const form = document.createElement("form");
      form.method = method;
      form.action = actionUrl;
      //   form.target = target;

      // Add form fields
      fields.forEach((field) => {
        const input = document.createElement("input");
        input.type = field.type;
        input.name = field.name;
        input.value = field.value;
        form.appendChild(input);
      });

      // Append the form to the body
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the body after submission
      document.body.removeChild(form);
    },
    toggleAdvancedOptions() {
      this.advancedOptionsVisible = !this.advancedOptionsVisible;
    },
    triggerFileInputClick() {
      event.stopPropagation();
      this.$nextTick(() => {
        document.getElementById("fileInput").click();
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.blastForm.QueryText = e.target.result;
        };
        reader.readAsText(file);
      }
    },
  },
};
</script>

<style scoped>
.mainheader {
  font-size: 12pt;
  font-weight: bold;
  color: #930;
}
.container {
  max-width: 100%;
}
.form-bg {
  background: #f1efec;
}
p {
  font-size: 12px;
}
.form-group {
  margin-bottom: 0.5rem;
}
</style>
