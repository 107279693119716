<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <div class="d-flex align-items-center">
            <h4>Gene Class Symbol: {{ json_data.symbol }}</h4>
            <PremiumIndicator />
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/genesymbol?key=1005833661
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import PremiumIndicator from "@/components/common/PremiumIndicator";
export default {
  name: "SymbolDetail",
  components: {
    BaseEntry,
    PageNotFound,
    PremiumIndicator,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "genesymbol/",
      json_data: null,
      side_cols: ["Summary", "Associated Loci", "Attribution"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let jsonId = this.$route.query.key; // Adjust the key as per your routing setup
    if (jsonId) {
      try {
        this.loading = true;
        let response = await PostsService.getGeneSymbolDetail({ key: jsonId });
        this.json_data = response.data;
        this.processEntries();
        this.loading = false;
        document.title = `Gene Class Symbol Detail`;
      } catch (error) {
        console.error("Error fetching Gene Class Symbol details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Associated Loci":
            entry.type = "bands";
            entry.bands = this.fillLoci();
            break;
          case "Attribution":
            entry.type = "bands";
            entry.bands = this.fillAttributions();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Symbol",
        text: this.json_data.symbol,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "Symbol Type",
        text:
          this.json_data.hasMutantPhenotype == "T"
            ? "Mutant Phenotype"
            : "Gene Product",
      });
      bands.push({
        key: "Symbol Full Name",
        text: this.json_data.descriptiveSymbol,
      });
      bands.push({
        key: "Description",
        text: this.json_data.description,
      });
      if (this.json_data.hasMutantPhenotype != "F") {
        bands.push({
          key: "Phenotype Class",
          text: this.json_data.mutantPhenotypeClass
            ? this.json_data.mutantPhenotypeClass
            : "No data available",
        });
      }
      bands.push({
        key: "Status",
        text: this.json_data.status,
      });

      return bands;
    },
    fillLoci() {
      let bands = [];
      let associatedLociCount = "";
      if (this.json_data.associatedLociCount === "multiple_loci") {
        associatedLociCount = "Symbol Describes >1 Gene";
      } else if (this.json_data.associatedLociCount === "single_locus") {
        associatedLociCount = "Symbol Describes 1 Gene";
      } else {
        associatedLociCount = "No Data available";
      }
      bands.push({
        key: "Associated Loci Count",
        text: associatedLociCount,
      });
      bands.push(this.getAssociatedLoci());
      return bands;
    },
    fillAttributions() {
      let bands = [];
      bands.push(this.getAttributtions());
      return bands;
    },

    //Getters
    getAssociatedLoci() {
      let entry = {
        key: "Associated Loci",
        type: "table",
        items: [],
        fields: [],
      };
      let associatedLoci = this.json_data.associatedLocis;
      if (associatedLoci == null || associatedLoci.length == 0) {
        entry.type = "";
        entry.text = "No Associated Loci available";
        return entry;
      }
      entry.fields = [
        {
          key: "locus",
          label: "Locus",
          cellType: "name_link",
        },
        {
          key: "gene",
          label: "Representative Gene Model",
          cellType: "name_link",
        },
      ];
      associatedLoci.forEach((a) => {
        if (a.locusName != null) {
          let item = {
            locus: { name: a.locusName, link: `/locus?key=${a.locusId}` },
            gene: { name: a.geneName, link: `/gene?key=${a.geneId}` },
          };
          entry.items.push(item);
        }
      });
      if (entry.items.length == 0) {
        entry.type = "";
        entry.text = "No Associated Loci available";
        return entry;
      }
      entry.count = entry.items.length;
      return entry;
    },
    getAttributtions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attribution available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        // Determine the correct link based on the communityType
        let linkPath = "/organization?key=" + a.communityId;
        if (a.communityType === "person") {
          linkPath = "/person?key=" + a.communityId; // Adjust path for persons
        }
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: linkPath,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
