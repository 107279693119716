<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="custom-navbar">
      <b-navbar-toggle target="nav-collapse" class="toggle"
        >Menu</b-navbar-toggle
      >
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="w-100">
          <b-nav-item-dropdown
            v-for="(m, i) in mergedMenuItems"
            :key="i"
            :text="m.title"
            class="flex-grow-1 text-left"
            :class="{ 'menu-active': clickedMenuIndex === i }"
            @click="clickedMenuIndex = i"
            style="border-right: 1px solid #777; padding-right: 1rem"
          >
            <b-dropdown-item v-for="(s, j) in m.subs" :key="j">
              <a
                href="#"
                @click.prevent="handleNavigation(s.route)"
                style="
                  color: #777;
                  display: block;
                  width: 100%;
                  padding: 0.25rem 1.5rem;
                  display: flex;
                  align-items: center;
                "
              >
                <span style="display: flex; align-items: center">
                  {{ s.title }}
                  <img
                    v-if="
                      premiumTools.includes(s.title) ||
                      premiumDownloads.includes(s.title)
                    "
                    src="@/assets/diamond.svg"
                    height="20px"
                    alt="Premium"
                    class="ml-2"
                  />
                </span>
              </a>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getDefaultJBrowseUrl,
  getDefaultJBrowse2Url,
} from "@/services/JBrowseService";

export default {
  data() {
    return {
      clickedMenuIndex: null,
      premiumTools: [
        "JBrowse",
        "JBrowse2",
        "PhyloGenes",
        "GBrowse",
        "SeqViewer",
        "MapViewer",
        "BLAST",
        "Patmatch",
        "Motif Analysis",
        "Chromosome Map Tool",
        "Synteny Viewer",
        "Functional Categorization",
      ],
      premiumDownloads: ["Subscriber Data Releases"],
    };
  },
  computed: {
    ...mapGetters("downloadItems", ["getDownloadItems"]),
    ...mapGetters("newsURLs", ["getHowToPostAJobURL", "getICARAbstractsURL"]),
    menuItemsBeforeDownload() {
      return [
        {
          title: "Advanced Search",
          subs: [
            {
              title: "Overview",
              route: "/search/overview",
            },
            {
              title: "DNA/Clones",
              route: "/search/dna",
            },
            {
              title: "Ecotypes",
              route: "/search/ecotype",
            },
            {
              title: "Genes",
              route: "/search/genes",
            },
            {
              title: "Germplasms",
              route: "/search/germplasm",
            },
            {
              title: "Keywords",
              route: "/search/keyword",
            },
            {
              title: "Markers",
              route: "/search/markers",
            },
            {
              title: "People/Labs",
              route: "/search/community",
            },
            {
              title: "Polymorphisms/Alleles",
              route: "/search/polyallele",
            },
            {
              title: "Protein",
              route: "/search/protein",
            },
            {
              title: "Protocol",
              route: "/search/protocol",
            },
            // {
            //   title: "Germplasm",
            //   route: "/search/germplasm",
            // },
            {
              title: "Publications",
              route: "/search/publication",
            },
            {
              title: "Transposons",
              route: "/search/transposon",
            },
          ],
        },
        {
          title: "Browse",
          subs: [
            {
              title: "Overview",
              route: "/browse/overview",
            },
            {
              title: "ABRC Catalog",
              route: "https://abrc.osu.edu/catalog",
            },
            {
              title: "Gene Class Symbols",
              route: "/browse/gene_symbols",
            },
            {
              title: "Recently Added Literature",
              route: "/browse/recent_literature",
            },
            {
              title: "Transposon Families",
              route: "/browse/transposon_families",
            },
            {
              title: "Microarray Experiments",
              route: "/browse/microarray_experiments",
            },
            {
              title: "Gene Families",
              route: "/browse/gene_family",
            },
            {
              title: "2010 Projects",
              route:
                "https://www.nsf.gov/awardsearch/advancedSearchResult?PIId=&PIFirstName=&PILastName=&PIOrganization=&PIState=&PIZip=&PICountry=&ProgOrganization=&ProgEleCode=&BooleanElement=All&ProgRefCode=&BooleanRef=All&Program=the+2010+project&ProgOfficer=&Keyword=&AwardNumberOperator=&AwardAmount=&AwardInstrument=&ExpiredAwards=true&OriginalAwardDateOperator=&StartDateOperator=&ExpDateOperator=",
            },
            {
              title: "The Arabidopsis Book (TAB)",
              route: "https://bioone.org/journals/the-arabidopsis-book/issues",
            },
          ],
        },
        {
          title: "Tools",
          subs: [
            {
              title: "Overview",
              route: "/tools/overview",
            },
            {
              title: "GO Term Enrichment",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/tools/go_term_enrichment.jsp`,
            },
            {
              title: "JBrowse",
              route: getDefaultJBrowseUrl(),
            },
            {
              title: "JBrowse2",
              route: getDefaultJBrowse2Url(),
            },
            {
              title: "PhyloGenes",
              route: `${process.env.VUE_APP_PHYLOGENES}`,
            },
            {
              title: "GBrowse",
              route: `${process.env.VUE_APP_GBROWSE}/cgi-bin/gb2/gbrowse/arabidopsis/`,
            },
            {
              title: "Synteny Viewer",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/cgi-bin/syntenyviewer2/showSynteny.pl`,
            },
            {
              title: "SeqViewer",
              route: `${process.env.VUE_APP_SEQVIEWER}`,
            },
            {
              title: "MapViewer",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/mapper`,
            },
            {
              title: "Functional Categorization",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/tools/bulk/go/index.jsp`,
            },
            {
              title: "AraCyc Metabolic Pathways",
              route: "https://pmn.plantcyc.org/organism-summary?object=ARA",
            },
            {
              title: "Integrated Genome Browser",
              route: "https://bioviz.org/",
            },
            {
              title: "BLAST",
              route: `/tools/blast/`,
            },
            {
              title: "Patmatch",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/cgi-bin/patmatch/nph-patmatch.pl`,
            },
            {
              title: "Motif Analysis",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/tools/bulk/motiffinder/index.jsp`,
            },
            {
              title: "Chromosome Map Tool",
              route: `${process.env.VUE_APP_OLD_TAIR_URL}/jsp/ChromosomeMap/tool.jsp`,
            },
            {
              title: "Textpresso Full Text",
              route: "https://arabidopsis.textpresso.org/tpc/home",
            },
          ],
        },
        {
          title: "Portals",
          subs: [
            {
              title: "Overview",
              route: "/portals/overview",
            },
            {
              title: "Community Resources",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Resources",
            },
            {
              title: "Clones/DNA Resources",
              route:
                "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=8160428",
            },
            {
              title: "Education and Outreach",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Education+and+Outreach+Resources",
            },
            {
              title: "Gene Expression Resources",
              route:
                "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=8160054",
            },
            {
              title: "Genome Annotation",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Genome+Annotation",
            },
            {
              title: "MASC/Functional Genomics",
              route: "https://arabidopsisresearch.org/",
            },
            {
              title: "Metabolomics Resources",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Metabolomics+Resources",
            },
            {
              title: "Mutant and Mapping Resources",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Mutant+and+Mapping+Resources",
            },
            {
              title: "Nomenclature",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Nomenclature",
            },
            {
              title: "Proteome Resources",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Proteomics+Resources",
            },
          ],
        },
      ];
    },
    wrappedDownloadItems() {
      return {
        title: "Download",
        subs: [
          { title: "Overview", route: "/download/overview" },
          ...this.getDownloadItems,
        ],
      };
    },
    menuItemsAfterDownload() {
      return [
        {
          title: "Submit",
          subs: [
            {
              title: "Overview",
              route: "/submit/overview",
            },
            {
              title: "ABRC Stock Donation",
              route: "https://abrc.osu.edu/",
            },
            {
              title: "External Links",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-ExternalLinks",
            },
            // {
            //   title: "Gene Family Data",
            //   route:
            //     "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneClassSymbolRegistration",
            // },
            {
              title: "Online Submission for Authors and Others",
              route: "https://goat.phoenixbioinformatics.org/",
            },
            {
              title: "Gene Structure Additions/Modifications",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneStructureAdditions/Modifications",
            },
            {
              title: "Marker and Polymorphism Data",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-MarkerandPolymorphismData",
            },
            {
              title: "Gene Class Symbol Registration",
              route: "/submit/gene_class_symbol_registration",
            },
            {
              title: "Phenotypes",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Phenotypes",
            },
            {
              title: "Protocols",
              route:
                "https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Protocols",
            },
          ],
        },
        {
          title: "News",
          subs: [
            {
              title: "Overview",
              route: "/news/overview",
            },
            {
              title: "TAIR News",
              route: "/news/breaking-news",
            },
            // {
            //   title: "TAIR Newsletter Archive",
            //   route: "",
            // },
            {
              title: "Conferences and Events",
              route: "/news/events",
            },
            {
              title: "ICAR Abstracts",
              route: this.getICARAbstractsURL,
            },
            {
              title: "Job Postings",
              route: "/news/jobs",
            },
            {
              title: "How to Post a Job",
              route: this.getHowToPostAJobURL,
            },
          ],
        },
        {
          title: "Stocks",
          subs: [
            {
              title: "Overview",
              route:
                "https://conf.arabidopsis.org/pages/viewpage.action?pageId=8160428",
            },
            {
              title: "ABRC",
              route: "http://abrc.osu.edu/",
            },
            {
              title: "NASC",
              route: "http://arabidopsis.info/",
            },
            {
              title: "RIKEN",
              route: "http://epd.brc.riken.jp/en/",
            },
          ],
        },
      ];
    },
    mergedMenuItems() {
      // Combining local data and Vuex data

      const items = [
        ...this.menuItemsBeforeDownload,
        this.wrappedDownloadItems,
        ...this.menuItemsAfterDownload,
      ];
      return items;
    },
  },
  methods: {
    handleNavigation(route) {
      // Check if the route is an external URL
      if (this.isExternal(route)) {
        // Open the external URL
        window.open(route, "_blank");
      } else {
        // Use Vue Router for internal routes
        this.$router.push(route);
      }
    },

    isExternal(url) {
      const externalRegex = /^(https?|ftp|mailto)?:\/\//;
      return externalRegex.test(url);
    },
  },
};
</script>
<style scoped lang="scss">
.toggle {
  color: black;
}
.custom-navbar {
  background-color: #dedcd2 !important;
  .b-nav-item-dropdown:hover {
    background-color: #f0f0f0;
  }
}
.custom-navbar .nav-item-dropdown {
  text-align: left;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .custom-navbar .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  .custom-navbar .nav-item-dropdown {
    width: 100%;
    text-align: center;
  }
}
.custom-dropdown-item {
  color: black;
}
</style>
