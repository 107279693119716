<template>
  <div class="ml-4 mr-4">
    <!-- Existing buttons -->
    <div class="mb-2 d-flex justify-content-start" v-if="data.buttons">
      <a
        v-for="(btn, i) in data.buttons"
        :key="i"
        :href="btn.url"
        class="btn btn-info ml-2"
        target="_blank"
        >{{ btn.name }}</a
      >
    </div>

    <!-- Table with pagination info -->
    <div v-if="data.items.length > 0">
      <div v-if="data.items.length > perPageOptions[0]" class="d-flex justify-content-end mb-2">
        <div class="d-flex align-items-center">
          <span class="mr-2">Results per page:</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            size="sm"
            class="w-auto"
          ></b-form-select>
        </div>
      </div>

    <b-table
      v-if="data.items.length > 0"
        striped
        hover
        responsive
        :items="data.items"
        :fields="data.fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template
          v-for="field in getAllFields(data.fields)"
          #[`head(${field.key})`]="data"
        >
          <table-header
            :data="data"
            :key="'h-' + field.key"
            :class="{
              'sorted-asc': sortKey === field.key && sortOrder === 1,
              'sorted-desc': sortKey === field.key && sortOrder === -1,
            }"
            @header-clicked="sortTable(field.key)"
          ></table-header>
        </template>
        <template
          v-for="field1 in getNameLinkFields(data.fields)"
          #[`cell(${field1.key})`]="data"
        >
          <div class="text-left" :key="field1.key">
            <link-wrapper
              v-if="data.item[field1.key]"
              :link="data.item[field1.key].link"
              :name="data.item[field1.key].name"
            />
          </div>
        </template>
        <template
          v-for="field2 in getLinkListFields(data.fields)"
          #[`cell(${field2.key})`]="data"
        >
          <div class="text-left" :key="field2.key">
            <span
              v-for="(list_obj, i) in data.item[field2.key].link_list"
              :key="i"
            >
              <span
                v-if="data.item[field2.key] && data.item[field2.key].link_list"
              >
                <link-wrapper
                  v-if="list_obj"
                  :link="list_obj.link"
                  :name="list_obj.name"
                />
                &nbsp;
              </span>
            </span>
          </div>
        </template>
        <template
          v-for="fieldLCS in getCommaListFields(data.fields)"
          #[`cell(${fieldLCS.key})`]="data"
        >
          <div
            class="text-left"
            v-if="data.item[fieldLCS.key]"
            :key="fieldLCS.key"
          >
            <span
              v-for="(list_obj, i) in data.item[fieldLCS.key].link_commasep"
              :key="i"
            >
              <link-wrapper
                v-if="list_obj && list_obj.link"
                :link="list_obj.link"
                :name="list_obj.name"
              />
              <span v-else>{{ list_obj.name }}</span>
              <span v-if="i < data.item[fieldLCS.key].link_commasep.length - 1"
                >;
              </span>
            </span>
          </div>
        </template>
        <template
          v-for="field3 in getLinkRows(data.fields)"
          #[`cell(${field3.key})`]="data"
        >
          <div class="d-flex flex-column" :key="field3.key">
            <span
              v-for="(list_obj, i) in data.item[field3.key].link_rows"
              :key="i"
            >
              <span v-if="data.item[field3.key]">
                <link-wrapper
                  v-if="list_obj"
                  :link="list_obj.link"
                  :name="list_obj.name"
                />
                &nbsp;
              </span>
            </span>
          </div>
        </template>
        <template
          v-for="field4 in getHtmlFields(data.fields)"
          #[`cell(${field4.key})`]="data"
        >
          <div
            class="text-left"
            v-html="data.item[field4.key].html"
            :key="field4.key"
          ></div>
        </template>
      </b-table>
      
      <!-- Pagination controls - only shown when items exceed smallest per-page option -->
      <div v-if="data.items.length > perPageOptions[0]" class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="data.items.length"
          :per-page="perPage"
          :align="center"
          class="my-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableHeader from "@/components/common/TableHeader.vue";
import LinkWrapper from "@/components/common/LinkWrapper.vue";

export default {
  components: {
    TableHeader,
    LinkWrapper,
  },
  mounted() {},
  props: ["data", "title"],
  data() {
    return {
      sortKey: "",
      sortOrder: 1,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50]
    };
  },
  watch: {
    perPage() {
      // Reset to first page when changing items per page
      this.currentPage = 1;
    }
  },
  methods: {
    sortTable(column) {
      if (this.sortKey === column) {
        this.sortOrder = this.sortOrder * -1;
      } else {
        this.sortKey = column;
        this.sortOrder = 1;
      }
      if (this.data.items && this.data.items.length) {
        this.data.items.sort((a, b) => {
          let valA = a[this.sortKey];
          let valB = b[this.sortKey];

          // Handle null, undefined, or empty strings
          if (!valA || valA === "") return 1;
          if (!valB || valB === "") return -1;

          // Handle link_list column
          if (column === "link_list" && valA.link_list && valA.link_list[0]) {
            valA = valA.link_list[0].name;
            valB = valB.link_list[0].name;
          }

          // Handle single link columns like name_link
          if (valA && valA.name) {
            valA = valA.name;
          }
          if (valB && valB.name) {
            valB = valB.name;
          }

          // Handle numbers
          if (typeof valA === "number" && typeof valB === "number") {
            return (valA - valB) * this.sortOrder;
          }

          //Handle html
          if (typeof valA === "object" && valA.html) {
            valA = valA.sortField;
          }
          if (typeof valB === "object" && valB.html) {
            valB = valB.sortField;
          }

          // Convert to string and handle strings
          valA = String(valA).toLowerCase();
          valB = String(valB).toLowerCase();

          if (valA < valB) return -1 * this.sortOrder;
          if (valA > valB) return 1 * this.sortOrder;
          return 0;
        });
      }
    },
    getAllFields(fields) {
      if (!fields) return [];
      return fields;
    },
    getNameLinkFields(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "name_link");
    },
    getLinkListFields(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "link_list");
    },
    getCommaListFields(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "link_commasep");
    },
    getLinkRows(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "link_rows");
    },
    getHtmlFields(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "html");
    },
  },
};
</script>

<style scoped>
.per-page-select {
  width: 80px;
}
</style>