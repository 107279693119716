import axios from "axios";

export default () => {
  // Create an Axios instance with your base URL
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
  });

  // Response interceptor for handling 401 responses with redirectUri
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(
        "Interceptor caught error:",
        error.response ? error.response.status : undefined
      );
      // Applicable to both meter block or need login page
      if (error.response && error.response.status === 401) {
        // Handle blob responses differently
        if (
          error.response.config &&
          error.response.config.responseType === "blob" &&
          error.response.data instanceof Blob
        ) {
          try {
            // Try to read the blob as text
            const blobText = await error.response.data.text();
            try {
              const jsonData = JSON.parse(blobText);
              if (jsonData.redirectUri) {
                window.location.href = `${
                  jsonData.redirectUri
                }&redirect=${encodeURIComponent(window.location.href)}`;
                return Promise.reject(
                  new Error("Redirecting due to authentication required")
                );
              }
            } catch (parseError) {
              console.error("Error parsing blob response:", parseError);
            }
          } catch (blobError) {
            console.error("Error reading blob:", blobError);
          }
        } else if (error.response.data && error.response.data.redirectUri) {
          // Regular JSON response
          window.location.href = `${
            error.response.data.redirectUri
          }&redirect=${encodeURIComponent(window.location.href)}`;
          return Promise.reject(
            new Error("Redirecting due to authentication required")
          );
        }
      }
      return Promise.reject(error);
    }
  );

  // Return the configured Axios instance
  return axiosInstance;
};
