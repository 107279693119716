<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div v-if="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <div class="d-flex align-items-center">
            <h4>CloneEnd: {{ json_data.name }}</h4>
            <PremiumIndicator />
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/cloneend?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import PremiumIndicator from "@/components/common/PremiumIndicator";
export default {
  name: "CloneEndDetail",
  components: {
    BaseEntry,
    PageNotFound,
    PremiumIndicator,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "cloneend/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let cloneEndId = this.$route.query.key; // Adjust the key as per your routing setup
    if (this.$route.query.accession) {
      let response2 = await PostsService.getCloneEndIdByAccession(
        this.$route.query.accession
      );
      cloneEndId = response2.data;
      if (cloneEndId == null) {
        this.error = true;
      }
    }
    if (cloneEndId) {
      try {
        let response = await PostsService.getCloneDetail({ key: cloneEndId });
        this.json_data = response.data;
        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Clone End Detail`;
      } catch (error) {
        console.error("Error fetching clone end details: ", error);
        this.loading = false;
        this.error = true;
      }
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "TAIR Accession",
        text: `CloneEnd:${this.json_data.tairObjectId}`,
      });
      bands.push({
        key: "Associated Locus",
        type: "link",
        text: this.json_data.locus
          ? this.json_data.locus.locusName
          : "No data available",
        link: this.json_data.locus
          ? `/locus?key=${this.json_data.locus.locusId}`
          : null,
      });
      bands.push({
        key: "Type",
        text: this.json_data.cloneEndType,
      });
      bands.push({
        key: "Chromosome",
        text: this.json_data.chromosome,
      });
      bands.push(this.getClone());
      bands.push(this.getSequences());
      bands.push(this.getMapLocations());
      // bands.push(this.getMapLinks());
      bands.push(this.getComments());
      bands.push(this.getAttributions());
      bands.push(this.getPublications());
      return bands;
    },

    //Getters
    getClone() {
      let entry = {
        key: "Clone",
        type: "table",
        items: [],
        fields: [],
      };
      let clone = this.json_data.clones;
      if (clone == null || clone.length == 0) {
        entry.type = "";
        entry.text = "No Clone available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "vectorType",
          label: "Vector Type",
          cellType: "name_link",
        },
        {
          key: "insertType",
          label: "Insert Type",
          cellType: "name_link",
        },
        {
          key: "stock",
          label: "Stock#",
          cellType: "name_link",
        },
      ];
      entry.items = [
        {
          name: { name: clone.name, link: `/clone?key=${clone.cloneId}` },
          vectorType: { name: clone.vectorType },
          insertType: { name: clone.insertType },
          stock: {
            name: clone.stockName,
            link: `https://abrc.osu.edu/stocks/number/${clone.stockName}`,
          },
        },
      ];
      return entry;
    },
    getSequences() {
      let entry = {
        key: "Sequences",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "bioSrc",
        label: "Bio Source",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "source",
        label: "Source",
        cellType: "name_link",
      });
      entry.fields.push({ key: "date", label: "Date", cellType: "name_link" });
      entry.fields.push({
        key: "genBank",
        label: "GenBank",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "sequence",
        label: "Sequence",
        cellType: "name_link",
      });

      let sequences = this.json_data.sequences; // Adjust the path according to your data structure
      if (!sequences || sequences.length === 0) {
        entry.type = "";
        entry.text = "No Sequences available";
        return entry;
      }

      // Map sequence data to table items
      sequences.map((s) => {
        let item = {
          bioSrc: { name: s.nucleotideSequenceType },
          source: { name: s.source },
          date: { name: this.dateToYMD(s.attributionDate) },
          genBank: {
            name: s.icAccession,
            link: `https://www.ncbi.nlm.nih.gov/nucleotide/${s.icAccession}`,
          },
          sequence: {
            name: s.sequenceClass,
            link: `/sequence?key=${s.nucleotideSeqId}`,
          },
        };
        entry.items.push(item);
      });

      return entry;
    },
    getMapLinks() {
      let entry = {
        key: "Map Links",
        type: "link_list",
        items: [],
      };
      entry.items.push({
        text: "Sequence Viewer",
        link: `${process.env.VUE_APP_SEQVIEWER}/?action=accession&type=clone&id=${this.json_data.sequenceViewerId}&chr=${this.json_data.chrom}`,
      });
      entry.items.push({
        text: "GBrowse",
        link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/tools/gbrowse/arabidopsis?name=${this.json_data.gbrowseId}`,
      });
      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "chrom",
        label: "Chromosome",
        cellType: "name_link",
      });
      entry.fields.push({ key: "map", label: "Map", cellType: "name_link" });
      entry.fields.push({
        key: "mapType",
        label: "Map Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "startEnd",
        label: "coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "orientation",
        label: "Orientation",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "attrib",
        label: "Attrib",
        cellType: "name_link",
      });

      let mapLocations = this.json_data.globalAssignmentDetails; // Adjust the path according to your data structure
      if (!mapLocations || mapLocations.length === 0) {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }

      // Map map location data to table items
      entry.items = mapLocations.map((loc) => {
        let orientation = "";
        if (loc.orientation == "F") {
          orientation = "forward";
        } else if (loc.orientation == "R") {
          orientation = "reverse";
        } else {
          orientation = "unknwon";
        }
        let mapLinkType = "map";
        if (loc.mapType == "assembly_unit") {
          mapLinkType = "assembly_unit";
        }
        return {
          chrom: { name: loc.chromosome },
          map: {
            name: loc.name,
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=${mapLinkType}&id=${loc.mapId}`,
          },
          mapType: { name: loc.mapType },
          startEnd: {
            name: `${loc.startPosition} - ${loc.endPosition} ${loc.units}`,
          },
          orientation: { name: orientation },
          attrib:
            loc.attributionIdForLink && loc.attributionIdForLink != 0
              ? {
                  name: "details",
                  link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${loc.attributionIdForLink}&type=global_assignment`, // Adjust link format as needed
                }
              : { name: "" },
        };
      });

      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let a_link = "";
        if (a.communityType == "organization") {
          a_link = `/organization?key=${a.communityId}`;
        } else {
          a_link = `/person?key=${a.communityId}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: a_link,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              "${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let publications = this.json_data.publications;
      if (publications == null || publications.length == 0) {
        entry.type = "";
        entry.text = "No publications available";
        return entry;
      }
      entry.fields = [
        {
          key: "title",
          label: "Title",
          cellType: "name_link",
        },
        {
          key: "source",
          label: "Source",
          cellType: "name_link",
        },
        {
          key: "year",
          label: "Year",
          cellType: "name_link",
        },
      ];
      publications.forEach((p) => {
        let item = {
          title: { name: p.title, link: `/publication?key=${p.referenceId}` },
          source: { name: p.sourceName },
          year: { name: p.publicationYear },
        };
        entry.items.push(item);
      });
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
