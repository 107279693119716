<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <div class="d-flex align-items-center">
            <h4>Library: {{ json_data.name }}</h4>
            <PremiumIndicator />
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/library?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import PremiumIndicator from "@/components/common/PremiumIndicator";
export default {
  name: "LibraryDetail",
  components: {
    BaseEntry,
    PageNotFound,
    PremiumIndicator,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "library/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let jsonId = this.$route.query.key;
    if (jsonId) {
      try {
        let response = await PostsService.getLibraryDetail({ key: jsonId });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Library Detail`;
      } catch (error) {
        console.error("Error fetching library details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push(this.getStocks());
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "TAIR Accession",
        text: `Library:${this.json_data.tairAccession}`,
      });
      bands.push({
        key: "Aliases",
        text: this.json_data.aliases
          ? this.json_data.aliases.join(", ")
          : "No Alias Available",
      });
      bands.push({
        key: "Library Description",
        text: this.json_data.description,
      });
      bands.push({
        key: "Abbreviation",
        text: this.json_data.abbreviation,
      });
      bands.push({
        key: "Insert Type",
        text: this.json_data.insertType,
      });
      bands.push({
        key: "Insert Size Range",
        text: this.json_data.insertSizeRange,
      });
      if (
        this.json_data.restrictionEnzymes &&
        this.json_data.restrictionEnzymes.length > 0
      ) {
        let enzymes = this.json_data.restrictionEnzymes;
        bands.push({
          key: "Restriction Enzyme 1",
          text: enzymes[0].name,
          type: "link",
          link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=restrictionenzyme&id=${enzymes[0].id}`,
        });
        bands.push({
          key: "Restriction Enzyme 2",
          text: enzymes[1].name,
          type: "link",
          link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=restrictionenzyme&id=${enzymes[1].id}`,
        });
      }

      bands.push(this.getVector());
      bands.push(this.getHostStrain());
      // bands.push(this.getGermplasms());
      bands.push(this.getComments());
      bands.push(this.getAttributions());
      return bands;
    },

    //Getters
    getStocks() {
      let entry = {
        key: "Stock Information",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
      };
      entry.fields.push({
        key: "stockNo",
        label: "Stock #",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "availability",
        label: "Availability",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "donors",
        label: "Donors",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "donorStockNo",
        label: "Donor Stock #",
        cellType: "name_link",
      });
      // entry.fields.push({
      //   key: "description",
      //   label: "Description",
      //   cellType: "name_link",
      //   width: "100%",
      // });

      let stocks = this.json_data.stocks;
      if (stocks == null || stocks.length == 0) {
        entry.type = "";
        entry.text =
          "Search at the stock centers or contact an author of publication(s) describing this line.";
        return entry;
      }
      let stockMap = {};
      stocks.forEach((s) => {
        if (!stockMap[s.stockId]) {
          stockMap[s.stockId] = [];
        }
        stockMap[s.stockId].push(s);
      });

      Object.keys(stockMap).forEach((stockId) => {
        let stocks = stockMap[stockId];
        let donors = [];
        stocks.forEach((s) => {
          if (s.communityType == "person") {
            donors.push({
              name: `${s.firstName} ${s.lastName}`,
              link: `/person?key=${s.communityId}`,
            });
          } else if (s.communityType == "organization") {
            donors.push({
              name: s.organizationName,
              link: `/organization?key=${s.communityId}`,
            });
          }
        });

        let s = stocks[0];
        let item = {};
        item.stockNo = {
          name: s.name,
          link: `https://abrc.osu.edu/stocks/number/${s.name}`,
        };
        item.availability = {
          name: s.stockAvailabilityType,
        };
        item.donors = donors;
        item.donorStockNo = {
          name: s.donorStockNumber,
        };
        item.description = {
          name: s.description,
        };
        entry.items.push(item);
      });

      return entry;
    },
    getVector() {
      let entry = {
        key: "Vector",
        type: "table",
        items: [],
        fields: [],
      };
      let vector = this.json_data.vector;
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
      ];
      entry.items = [
        {
          name: {
            name: vector.name,
            link: `/vector?key=${vector.vectorId}`,
          },
          type: { name: vector.vectorType },
        },
      ];
      return entry;
    },
    getHostStrain() {
      let entry = {
        key: "Host Strain",
        type: "table",
        items: [],
        fields: [],
      };
      let hostStrain = this.json_data.hostStrains;
      entry.fields = [
        {
          key: "genus",
          label: "Genus",
          cellType: "name_link",
        },
        {
          key: "species",
          label: "Species",
          cellType: "name_link",
        },
        {
          key: "strain",
          label: "Strain Name",
          cellType: "name_link",
        },
      ];
      if (!hostStrain && hostStrain.length == 0) {
        entry.type = "";
        entry.text = "No Host Strain available";
        return entry;
      }
      hostStrain.forEach((h) => {
        let item = {
          genus: {
            name: h.genus,
          },
          species: {
            name: h.species,
          },
          strain: {
            name: h.strainName,
            link: `/hoststrain?key=${h.hostStrainId}`,
          },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getTaxon() {
      let entry = {
        key: "Taxon",
        type: "table",
        items: [],
        fields: [],
      };
      let taxon = this.json_data.taxon;
      entry.fields = [
        {
          key: "genus",
          label: "Genus",
          cellType: "name_link",
        },
        {
          key: "species",
          label: "Species",
          cellType: "name_link",
        },
        {
          key: "commonName",
          label: "Common Name",
          cellType: "name_link",
        },
        {
          key: "taxonomy",
          label: "Taxonomy",
          cellType: "name_link",
        },
      ];
      entry.items = [
        {
          genus: {
            name: taxon.genus,
          },
          species: {
            name: taxon.species,
          },
          commonName: {
            name: taxon.commonName,
          },
          taxonomy: {
            name: taxon.taxonomy,
          },
        },
      ];
      return entry;
    },
    getGermplasms() {
      let entry = {
        key: "Germplasms",
        type: "table",
        items: [],
        fields: [],
      };
      let germplasms = this.json_data.germplasms;
      if (germplasms == null || germplasms.length == 0) {
        entry.type = "";
        entry.text = "No Germplasms available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "description",
          label: "Description",
          cellType: "name_link",
        },
      ];
      germplasms.forEach((g) => {
        let item = {
          name: {
            name: g.name,
            link: `/germplasm?key=${g.germplasmId}`,
          },
          description: { name: g.description },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let a_link = "";
        if (a.communityType == "organization") {
          a_link = `/organization?key=${a.communityId}`;
        } else {
          a_link = `/person?key=${a.communityId}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: a_link,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_API_BASE_URL +
              "/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
