<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-9">
        <div class="card border-0 shadow">
          <!-- Header -->
          <div class="card-header bg-primary bg-gradient text-white p-4 border-0">
            <h3 class="mb-0 fw-light text-center">Create Your Account</h3>
            <!-- <p class="text-center mb-0 mt-2 text-white-50">Please fill in the information below</p> -->
          </div>
          
          <div class="card-body p-4 p-md-5" v-if="json_data">
            <form @submit.prevent="registerPerson" class="needs-validation" novalidate>

              <!-- Validation Notice -->
              <div class="alert alert-info border-0 bg-info bg-opacity-10 mb-4 text-white" role="alert">
                <i class="bi bi-info-circle-fill me-2"></i>
                Please note: Only English characters are allowed in all text fields.
              </div>

              <!-- Personal Information Section -->
              <div class="mb-5">
                <div class="d-flex align-items-center mb-4">
                  <h5 class="mb-0">Personal Information</h5>
                </div>

                <div class="row g-4">
                  <div class="col-md-6">
                    <label for="firstName" class="form-label pt-3 fw-semibold">First Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="firstName"
                      v-model="json_data.firstName"
                      @input="validateEnglishChars('firstName')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.firstName && validationErrors.firstName">{{ validationErrors.firstName }}</div>
                  </div>
                  
                  <div class="col-md-6">
                    <label for="lastName" class="form-label pt-3 fw-semibold">Last Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="lastName"
                      v-model="json_data.lastName"
                      @input="validateEnglishChars('lastName')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.lastName && validationErrors.lastName">{{ validationErrors.lastName }}</div>
                  </div>
                </div>
              </div>

              <!-- Account Information Section -->
              <div class="mb-5">
                <div class="d-flex align-items-center mb-4">
                  <h5 class="mb-0">Account Information</h5>
                </div>

                <div class="row g-4">
                  <div class="col-md-6">
                    <label for="username" class="form-label pt-3 fw-semibold">Username <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text bg-light border-end-0">
                        <i class="bi bi-person-badge"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control form-control-lg bg-light border-start-0"
                        id="username"
                        v-model="json_data.username"
                        @input="validateEnglishChars('username')"
                        required
                      />
                    </div>
                    <div class="text-danger small" v-if="touchedFields.username && validationErrors.username">{{ validationErrors.username }}</div>
                  </div>
                  
                  <div class="col-md-6">
                    <label for="email" class="form-label pt-3 fw-semibold">Email <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text bg-light border-end-0">
                        <i class="bi bi-envelope"></i>
                      </span>
                      <input
                        type="email"
                        class="form-control form-control-lg bg-light border-start-0"
                        id="email"
                        v-model="json_data.email"
                        @input="validateEmail"
                        required
                      />
                    </div>
                    <div class="text-danger small" v-if="touchedFields.email && validationErrors.email">{{ validationErrors.email }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="password" class="form-label pt-3 fw-semibold">Password <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text bg-light border-end-0">
                        <i class="bi bi-key"></i>
                      </span>
                      <input
                        type="password"
                        class="form-control form-control-lg bg-light border-start-0"
                        id="password"
                        v-model="json_data.password"
                        @input="validateEnglishChars('password')"
                        required
                      />
                    </div>
                    <div class="form-text" v-if="!passwordError && !validationErrors.password">Must be between 6 and 10 characters</div>
                    <div class="text-danger small" v-if="passwordError">{{ passwordError }}</div>
                    <div class="text-danger small" v-if="touchedFields.password && validationErrors.password">{{ validationErrors.password }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="password_confirm" class="form-label pt-3 fw-semibold">Confirm Password <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text bg-light border-end-0">
                        <i class="bi bi-key-fill"></i>
                      </span>
                      <input
                        type="password"
                        class="form-control form-control-lg bg-light border-start-0"
                        id="password_confirm"
                        v-model="json_data.password_confirm"
                        @input="validateEnglishChars('password_confirm')"
                        required
                      />
                    </div>
                    <div class="text-danger small" v-if="confirmPasswordError">{{ confirmPasswordError }}</div>
                    <div class="text-danger small" v-if="touchedFields.password_confirm && validationErrors.password_confirm">{{ validationErrors.password_confirm }}</div>
                  </div>
                </div>
              </div>

              <!-- Professional Information Section -->
              <div class="mb-5">
                <div class="d-flex align-items-center mb-4">
                  <h5 class="mb-0">Professional Information</h5>
                </div>

                <div class="row g-4">
                  <div class="col-md-6">
                    <label for="institutionName" class="form-label pt-3 fw-semibold">Institution Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="institutionName"
                      v-model="json_data.institutionName"
                      @input="validateEnglishChars('institutionName')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.institutionName && validationErrors.institutionName">{{ validationErrors.institutionName }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="jobTitle" class="form-label pt-3 fw-semibold">Job Title <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="jobTitle"
                      v-model="json_data.jobTitle"
                      @input="validateEnglishChars('jobTitle')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.jobTitle && validationErrors.jobTitle">{{ validationErrors.jobTitle }}</div>
                  </div>
                </div>
              </div>

              <!-- Contact Information Section -->
              <div class="mb-5">
                <div class="d-flex align-items-center mb-4">
                  <h5 class="mb-0">Contact Information</h5>
                </div>

                <div class="row g-4">
                  <div class="col-md-6">
                    <label for="phone" class="form-label pt-3 fw-semibold">Phone <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <span class="input-group-text bg-light border-end-0">
                        <i class="bi bi-telephone"></i>
                      </span>
                      <input
                        type="tel"
                        class="form-control form-control-lg bg-light border-start-0"
                        id="phone"
                        v-model="json_data.phone"
                        @input="validatePhone"
                        required
                      />
                    </div>
                    <div class="text-danger small" v-if="touchedFields.phone && validationErrors.phone">{{ validationErrors.phone }}</div>
                  </div>

                  <div class="col-12">
                    <label for="streetAddress" class="form-label pt-3 fw-semibold">Street Address <span class="text-danger">*</span></label>
                    <textarea
                      class="form-control form-control-lg bg-light"
                      id="streetAddress"
                      v-model="json_data.address"
                      @input="validateEnglishChars('address')"
                      rows="2"
                      required
                    ></textarea>
                    <div class="text-danger small" v-if="touchedFields.address && validationErrors.address">{{ validationErrors.address }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="city" class="form-label pt-3 fw-semibold">City <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="city"
                      v-model="json_data.city"
                      @input="validateEnglishChars('city')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.city && validationErrors.city">{{ validationErrors.city }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="stateProvince" class="form-label pt-3 fw-semibold">State/Province <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="stateProvince"
                      v-model="json_data.state"
                      @input="validateEnglishChars('state')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.state && validationErrors.state">{{ validationErrors.state }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="country" class="form-label pt-3 fw-semibold">Country <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="country"
                      v-model="json_data.country"
                      @input="validateEnglishChars('country')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.country && validationErrors.country">{{ validationErrors.country }}</div>
                  </div>

                  <div class="col-md-6">
                    <label for="zipcode" class="form-label pt-3 fw-semibold">Zipcode <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control form-control-lg bg-light"
                      id="zipcode"
                      v-model="json_data.postalCode"
                      @input="validateEnglishChars('postalCode')"
                      required
                    />
                    <div class="text-danger small" v-if="touchedFields.postalCode && validationErrors.postalCode">{{ validationErrors.postalCode }}</div>
                  </div>
                </div>
              </div>

              <!-- Preferences Section -->
              <div class="mb-5">
                <div class="d-flex align-items-start mb-4">
                  <h5 class="mb-0">Preferences</h5>
                </div>

                <div class="row justify-content-start">
                  <div class="col-12 text-start">
                    <div class="d-flex flex-column align-items-start">
                      <div class="form-check mb-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="okMail"
                          v-model="json_data.okMail"
                        />
                        <label class="form-check-label" for="okMail">
                          OK to send occasional email announcements and quarterly TAIR newsletter
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="okGeneSymbol"
                          v-model="json_data.okGeneSymbol"
                        />
                        <label class="form-check-label" for="okGeneSymbol">
                          Enable Gene Symbol Registration
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Alerts -->
              <div v-if="successMessage" class="alert alert-success border-0 bg-success bg-opacity-10" role="alert">
                <i class="bi bi-check-circle-fill me-2"></i>
                {{ successMessage }}
              </div>

              <div v-if="formErrors.length" class="alert alert-danger py-3 px-4 shadow-sm rounded-3 border-start border-danger border-4">
                <!-- Simple Header -->
                <div class="d-flex align-items-center mb-2">
                  <h5 class="mb-0">Please fix the following errors:</h5>
                </div>
                
                <!-- Simple Error List -->
                <div class="ms-4">
                  <div v-for="error in formErrors" :key="error" class="d-flex align-items-baseline mb-1">
                    <div class="text-danger me-2">•</div>
                    <div>{{ error }}</div>
                  </div>
                </div>
              </div>

              <!-- Required Fields Note -->
              <div class="text-muted mb-4">
                <small><span class="text-danger">*</span> Required fields</small>
              </div>

              <!-- Submit Button -->
              <button
                type="submit"
                class="btn btn-primary btn-lg w-100"
                :disabled="isLoading"
              >
                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                <i v-else class="bi bi-person-plus-fill me-2"></i>
                {{ isLoading ? 'Creating Account...' : 'Create Account' }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//http://localhost:8082/register
import BaseEntry from "@/components/detail/BaseEntry";
import { mapGetters } from "vuex";
import Api from "@/services/Api";

export default {
  name: "RegistrationDetail",
  components: {
    BaseEntry,
  },
  data() {
    return {
      json_data: {
        username: "",
        password: "",
        password_confirm: "",
        email: "",
        okMail: false,
        okGeneSymbol: false,
        firstName: "",
        lastName: "",
        institutionName: "",
        jobTitle: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        labName: "",
      },
      formErrors: [],
      formattedAddress: "",
      loading: false,
      loadingError: false,
      successMessage: "",
      isLoading: false,
      passwordError: "",
      confirmPasswordError: "",
      validationErrors: {},
      touchedFields: {}, // Track which fields the user has interacted with
    };
  },
  computed: {
    hasValidationErrors() {
      return Object.keys(this.validationErrors).length > 0 || 
             this.passwordError !== "" || 
             this.confirmPasswordError !== "";
    }
  },
  async mounted() {
    document.title = "Person Registration";
    const link = document.createElement('link');
    link.href = 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    // No validation on initial load - wait for user interaction
  },
  watch: {
    "json_data.password"(newVal) {
      this.passwordError = "";
      if (newVal.length > 0 && (newVal.length < 6 || newVal.length > 10)) {
        this.passwordError = "Password must be between 6 and 10 characters.";
      }
    },
    "json_data.password_confirm"(newVal) {
      this.confirmPasswordError = "";
      if (newVal !== this.json_data.password) {
        this.confirmPasswordError = "Passwords do not match.";
      }
    },
  },
  methods: {
    ...mapGetters("authProfile", ["getLoginUrl"]),
    // Validate for English characters only
    validateEnglishChars(field) {
      // Mark this field as touched
      this.$set(this.touchedFields, field, true);
      
      const value = this.json_data[field];
      // Delete the error if the field is empty or if we're setting it to empty
      if (!value || value.length === 0) {
        this.$set(this.validationErrors, field, "This field is required.");
        return;
      }

      // Check for non-English characters using regex
      // This regex allows English letters, numbers, spaces, and common punctuation
      const englishOnlyRegex = /^[A-Za-z0-9\s.,!?'"`@#$%^&*()_\-+=:;{}[\]|\\/<>~]+$/;
      
      if (!englishOnlyRegex.test(value)) {
        this.$set(this.validationErrors, field, "Only English characters are allowed in this form.");
      } else {
        // Clear the error for this field
        this.$delete(this.validationErrors, field);
      }
    },
    
    // Validate Email Format
    validateEmail() {
      // Mark email as touched
      this.$set(this.touchedFields, 'email', true);
      
      const email = this.json_data.email;
      if (!email || email.length === 0) {
        this.$set(this.validationErrors, 'email', "Email address is required.");
        return;
      }
      
      // Basic email validation regex
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      
      if (!emailRegex.test(email)) {
        this.$set(this.validationErrors, 'email', "Please enter a valid email address in English (e.g., example@domain.com).");
      } else {
        // Clear the error for this field
        this.$delete(this.validationErrors, 'email');
      }
    },
    
    // Validate Phone Number
    validatePhone() {
      // Mark phone as touched
      this.$set(this.touchedFields, 'phone', true);
      
      const phone = this.json_data.phone;
      if (!phone || phone.length === 0) {
        this.$set(this.validationErrors, 'phone', "Phone number is required.");
        return;
      }
      
      // Phone validation - allow digits, spaces, parentheses, dashes, and plus sign
      const phoneRegex = /^[0-9\s()+\-]+$/;
      
      if (!phoneRegex.test(phone)) {
        this.$set(this.validationErrors, 'phone', "Phone number can only contain digits, spaces, and characters: ( ) + -");
      } else {
        // Clear the error for this field
        this.$delete(this.validationErrors, 'phone');
      }
    },
    
    // Check all fields for validation before submitting
    validateAllFields() {
      const textFields = [
        'firstName', 'lastName', 'username', 'password', 'password_confirm',
        'institutionName', 'jobTitle', 'address', 'city', 'state', 'country', 'postalCode'
      ];
      
      // Mark all fields as touched during form submission
      textFields.forEach(field => {
        this.$set(this.touchedFields, field, true);
      });
      this.$set(this.touchedFields, 'email', true);
      this.$set(this.touchedFields, 'phone', true);
      
      // Clear any previous validation errors
      this.validationErrors = {};
      
      // Validate all fields
      textFields.forEach(field => {
        if (!this.json_data[field] || this.json_data[field].trim() === '') {
          this.$set(this.validationErrors, field, `${this.getFieldLabel(field)} is required.`);
        } else {
          // Check for non-English characters
          const englishOnlyRegex = /^[A-Za-z0-9\s.,!?'"`@#$%^&*()_\-+=:;{}[\]|\\/<>~]+$/;
          if (!englishOnlyRegex.test(this.json_data[field])) {
            this.$set(this.validationErrors, field, `Only English characters are allowed in ${this.getFieldLabel(field)}.`);
          }
        }
      });
      
      // Validate email
      const email = this.json_data.email;
      if (!email || email.length === 0) {
        this.$set(this.validationErrors, 'email', "Email address is required.");
      } else {
        // Basic email validation regex
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
        if (!emailRegex.test(email)) {
          this.$set(this.validationErrors, 'email', "Please enter a valid email address in English (e.g., example@domain.com).");
        }
      }
      
      // Validate phone
      const phone = this.json_data.phone;
      if (!phone || phone.length === 0) {
        this.$set(this.validationErrors, 'phone', "Phone number is required.");
      } else {
        // Phone validation - allow digits, spaces, parentheses, dashes, and plus sign
        const phoneRegex = /^[0-9\s()+\-]+$/;
        
        if (!phoneRegex.test(phone)) {
          this.$set(this.validationErrors, 'phone', "Phone number can only contain digits, spaces, and characters: ( ) + -");
        }
      }
      
      return Object.keys(this.validationErrors).length === 0;
    },
    
    // Helper method to get human-readable field labels
    getFieldLabel(field) {
      const labels = {
        firstName: 'First Name',
        lastName: 'Last Name',
        username: 'Username',
        password: 'Password',
        password_confirm: 'Confirm Password',
        institutionName: 'Institution Name',
        jobTitle: 'Job Title',
        address: 'Street Address',
        city: 'City',
        state: 'State/Province',
        country: 'Country',
        postalCode: 'Zipcode'
      };
      
      return labels[field] || field.charAt(0).toUpperCase() + field.slice(1);
    },
    
    async registerPerson() {
      this.formErrors = [];
      
      // Collect all validation errors
      const allErrors = [];
      
      // Check password-specific errors
      if (this.passwordError) {
        allErrors.push(this.passwordError);
      }
      
      if (this.confirmPasswordError) {
        allErrors.push(this.confirmPasswordError);
      }
      
      // Validate all fields
      this.validateAllFields();
      
      // Add all validation errors to our errors list
      Object.values(this.validationErrors).forEach(error => {
        allErrors.push(error);
      });
      
      // If we have any errors, show them and stop form submission
      if (allErrors.length > 0) {
        this.formErrors = allErrors;
        return;
      }
      
      this.isLoading = true;
      
      try {
        const registerDetails = this.json_data;
        const response = await Api().post("user/register-person", registerDetails);
        this.isLoading = false;

        if (response.status === 200) {
          this.formErrors = [];
          this.successMessage = "Person registered successfully.";

          setTimeout(() => {
            this.successMessage = "";
            window.open(this.getLoginUrl(), "_self");
          }, 2000);
        }
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data) {
          if (typeof error.response.data === "object" && error.response.data.error) {
            this.formErrors.push(error.response.data.error);
          } else if (typeof error.response.data === "string") {
            this.formErrors.push(error.response.data);
          }
        } else {
          this.formErrors.push("An unexpected error occurred. Please try again.");
        }
      }
    },
    
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d.toString().padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.text-danger {
  font-size: 0.85rem;
  margin-top: 0.25rem;
}
</style>